import { Spinner } from 'common-ui';
import { ColConfig, getFieldConfig } from 'configs/columns';
import {
  CarveCardContainer,
  CarveDetailsLabel,
  CarveDetailsValue,
  CarveDetailsContainer,
  CarveDetailsRow,
  CarveName,
  CarveNameRow,
} from 'ui-kit';

import { useQuery } from '@apollo/client';

import { FilterableField } from '__generated__/globalTypes';

import { CarveSummary } from './__generated__/CarveSummary';
import { GetCarve, GetCarveVariables } from './__generated__/GetCarve';
import { GET_CARVE } from './fragments';

type CarveDiffTableProps = {
  dealId: string;
  filteredPool?: CarveSummary | null;
};

const configFiller = {
  inputFormat: null,
  sortSelector: null,
  filterSelector: null,
};

const CONFIG = {
  current_balance_cents: {
    ...(getFieldConfig(FilterableField.current_balance_cents) as ColConfig),
    string: 'C BAL',
  },
  original_balance_cents: {
    ...(getFieldConfig(FilterableField.original_balance_cents) as ColConfig),
    string: 'O BAL',
  },
  loan_count: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: '# LOANS',
    selector: 'loan_count',
  },
  wa_age_months: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: 'WALA',
    selector: 'wa_age_months',
  },
  wa_remaining_loan_terms_months: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: 'WAM',
    selector: 'wa_remaining_loan_terms_months',
  },
  wa_coupon: {
    ...configFiller,
    display: (value: number) => `${((value ?? 0) * 100).toFixed(3)}%`,
    string: 'GWAC',
    selector: 'wa_coupon',
  },
  wa_borrower_credit_score: {
    ...configFiller,
    display: (value: number) => value.toLocaleString(),
    string: 'FICO',
    selector: 'wa_borrower_credit_score',
  },
  wa_ltv: {
    ...configFiller,
    display: (value: number) => `${((value ?? 0) * 100).toFixed(2)}%`,
    string: 'LTV',
    selector: 'wa_ltv',
  },
  wa_dti: {
    ...configFiller,
    display: (value: number) => `${((value ?? 0) * 100).toFixed(2)}%`,
    string: 'DTI',
    selector: 'wa_dti',
  },
} as const;

const CarveDiffTable = ({
  dealId,
  filteredPool,
}: CarveDiffTableProps): JSX.Element => {
  const { loading, data, error } = useQuery<GetCarve, GetCarveVariables>(
    GET_CARVE,
    {
      variables: { id: dealId },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loading) return <Spinner loading />;
  if (error) return <div>{error.message}</div>;

  if (!data?.deal?.performance_summary) {
    return <></>;
  }

  const baseSummary = data.deal.performance_summary;
  const carveSummary = filteredPool;

  const hasCarveData =
    carveSummary != null && baseSummary.loan_count !== carveSummary.loan_count;

  return (
    <CarveCardContainer highlight={hasCarveData}>
      <CarveDetailsContainer>
        <CarveNameRow>
          <CarveDetailsLabel>{''}</CarveDetailsLabel>
          <CarveName className={hasCarveData ? 'w-[150px] bg-slate-700' : ''}>
            Base
          </CarveName>
          {hasCarveData && (
            <CarveName className="w-[150px]">Carve</CarveName>
          )}
        </CarveNameRow>

        {Object.keys(baseSummary)
          .filter((key) => key !== '__typename')
          .map((key, index) => {
            const fieldConfig = CONFIG[key as keyof CarveSummary];
            const label = fieldConfig.string;
            const baseValue = baseSummary[key as keyof CarveSummary];
            const carveValue = carveSummary?.[key as keyof CarveSummary];

            return (
              <CarveDetailsRow key={index}>
                <CarveDetailsLabel>{label}</CarveDetailsLabel>
                <CarveDetailsValue
                  className={hasCarveData ? 'w-[150px] bg-slate-700' : ''}
                >
                  {fieldConfig.display(baseValue)}
                </CarveDetailsValue>
                {hasCarveData && carveValue != null && (
                  <CarveDetailsValue className="w-[150px]">
                    {fieldConfig.display(carveValue)}
                  </CarveDetailsValue>
                )}
              </CarveDetailsRow>
            );
          })}
      </CarveDetailsContainer>
    </CarveCardContainer>
  );
};

export default CarveDiffTable;
export { default as CarveDiffTableFragments } from './fragments';
