import React, { useRef, useEffect } from 'react';

import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

export interface DropdownOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  options: DropdownOption[];
  placeholder?: string;
  onValueChange?: (value: DropdownOption) => void;
  isOpen?: boolean;
  onToggleOpen?: (open: boolean) => void;
  value?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder = 'Select...',
  onValueChange,
  isOpen: controlledIsOpen,
  onToggleOpen,
  className,
  value,
  ...props
}) => {
  const [internalIsOpen, setInternalIsOpen] = React.useState(false);
  const isOpen = controlledIsOpen ?? internalIsOpen;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    const newState = !isOpen;
    setInternalIsOpen(newState);
    onToggleOpen?.(newState);
  };

  const handleSelect = (option: DropdownOption) => {
    if (option.isDisabled) return;
    onValueChange?.(option);
    setInternalIsOpen(false);
    onToggleOpen?.(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setInternalIsOpen(false);
      onToggleOpen?.(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`text-m relative rounded-md border border-pink-500 bg-gray-950 px-4 py-2 text-white ${className}`}
      {...props}
    >
      <button
        type="button"
        onClick={toggleOpen}
        className="flex w-full items-center justify-between"
      >
        <span>{value || placeholder}</span>
        {isOpen ? (
          <IconChevronUp className="text-pink-400" size={20} />
        ) : (
          <IconChevronDown className="text-pink-400" size={20} />
        )}
      </button>
      {isOpen && (
        <ul className="absolute left-0 right-0 z-10 mt-2 rounded-md border border-pink-500 bg-gray-950 shadow-lg">
          {options.map((option) => (
            <li
              key={option.value}
              className={`px-4 py-2 text-sm ${
                option.isDisabled
                  ? 'cursor-default text-gray-500'
                  : 'cursor-pointer text-white hover:bg-gray-900'
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
