import React, { useState } from 'react';

import { IconFileCv, IconFileSpreadsheet } from '@tabler/icons-react';
import { BaseButton } from 'common-ui';
import { DownloadLink } from 'ui-kit';

import { gql } from '@apollo/client';

import { FileState } from '__generated__/globalTypes';

import { ProcessingReportCardFile } from './__generated__/ProcessingReportCardFile';
import { FileIngestOperationWrapper } from './FileIngestOperation';
import { useIngestFile } from './hooks/useIngestFile';

const ProcessingReportCardFragments = {
  loanTape: gql`
    fragment ProcessingReportCardFile on File {
      id
      state
      processingReport: processing_report {
        duplicate_count
        duplicate_records_csv_url
        duplicate_records_excel_url
        failed_count
        failed_records_csv_url
        failed_records_excel_url
        flagged_count
        flagged_records_csv_url
        flagged_records_excel_url
        new_count
        new_records_csv_url
        new_records_excel_url
        updated_count
        updated_records_csv_url
        updated_records_excel_url
      }
    }
  `,
};

export type Props = {
  selectedFile: ProcessingReportCardFile;
};

const ProcessingReportCard = (props: Props): JSX.Element => {
  const { processingReport, state, id } = props.selectedFile;
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const { ingestFile } = useIngestFile();
  const ingestEnabled = state === FileState.PROCESSED;

  const startFileIngest = async (fileId: string) => {
    setIsStarted(true);
    await ingestFile(fileId);
  };

  const rows = [
    {
      name: 'New Loans',
      count: processingReport.new_count,
      csvUrl: processingReport.new_records_csv_url,
      excelUrl: processingReport.new_records_excel_url,
      group: 'A',
    },
    {
      name: 'Updated Loans',
      count: processingReport.updated_count,
      csvUrl: processingReport.updated_records_csv_url,
      excelUrl: processingReport.updated_records_excel_url,
      group: 'A',
    },
    {
      name: 'Processed with Warnings',
      count: processingReport.flagged_count,
      csvUrl: processingReport.flagged_records_csv_url,
      excelUrl: processingReport.flagged_records_excel_url,
      group: 'A',
    },
    {
      name: 'Duplicate Loans',
      count: processingReport.duplicate_count,
      csvUrl: processingReport.duplicate_records_csv_url,
      excelUrl: processingReport.duplicate_records_excel_url,
      group: 'B',
    },
    {
      name: 'Error Loans',
      count: processingReport.failed_count,
      csvUrl: processingReport.failed_records_csv_url,
      excelUrl: processingReport.failed_records_excel_url,
      group: 'C',
    },
  ];

  const numberSaved =
    (processingReport.new_count || 0) + (processingReport.updated_count || 0);

  const getTextColorClass = (group: string) => {
    switch (group) {
      case 'A':
        return 'text-green-500'; // New/Updated/Processed with Warnings
      case 'B':
        return 'text-yellow-500'; // Duplicate
      case 'C':
        return 'text-red-500'; // Error
      default:
        return 'text-foreground-default';
    }
  };

  return (
    <div className="rounded-lg border border-custom-purple bg-background-canvas p-6">
      <h2 className="mb-4 text-left text-xl font-bold text-foreground-default">
        Field Mapping Report
      </h2>

      <div className="w-full max-w-[800px]">
        <div className="flex justify-between text-left text-sm font-medium text-foreground-muted">
          <div className="w-2/5">REPORT TYPE</div>
          <div className="w-1/5 text-right">COUNT</div>
          <div className="w-1/5 text-right">DOWNLOAD CSV</div>
          <div className="w-1/5 text-right">DOWNLOAD EXCEL</div>
        </div>

        <div className="mt-2">
          {rows.map((row, index) => (
            <div
              key={row.name}
              className={`flex items-center justify-between border-t 
                py-2 ${index > 0 && row.group !== rows[index - 1].group ? 'border-slate-400' : 'border-slate-600'}`}
            >
              <div className={`w-2/5 ${getTextColorClass(row.group)}`}>
                {row.name}
              </div>
              <div className="w-1/5 text-right text-foreground-subtle">
                {row.count}
              </div>
              <div className="flex w-1/5 justify-end">
                <DownloadLinks
                  count={row.count}
                  url={row.csvUrl}
                  icon={<IconFileCv size={16} />}
                  text="Download CSV"
                />
              </div>
              <div className="flex w-1/5 justify-end">
                <DownloadLinks
                  count={row.count}
                  url={row.excelUrl}
                  icon={<IconFileSpreadsheet size={16} />}
                  text="Download Excel"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-3 flex justify-end font-medium">
        <BaseButton
          onClick={() => startFileIngest(id)}
          disabled={!ingestEnabled}
          label={'save'}
          size="medium"
          type="secondary"
        >
          Save&nbsp;
          <span className={numberSaved > 0 ? 'text-green-500' : ''}>
            {numberSaved} Loans
          </span>
          &nbsp;to My Portfolio
        </BaseButton>
      </div>

      <FileIngestOperationWrapper
        fileState={props.selectedFile.state}
        isStarted={isStarted}
        setIsStarted={setIsStarted}
      />
    </div>
  );
};

type DownloadLinkProps = {
  count: number | null;
  url: string | null;
  icon: JSX.Element;
  text: string;
};

const DownloadLinks: React.FC<DownloadLinkProps> = ({
  count,
  url,
  icon,
  text,
}) => {
  return count === 0 || !url ? (
    <span className="flex cursor-not-allowed items-center justify-end text-slate-400 opacity-30">
      <span className="pr-1">{text}</span>
      {icon}
    </span>
  ) : (
    <DownloadLink url={url} icon={icon} text={text} />
  );
};

export default ProcessingReportCard;
export { ProcessingReportCardFragments };
