import { useState } from 'react';

import { Spinner } from 'common-ui';
import {
  filtersToQueryFilters,
  FilterValue,
  PillFilters,
} from 'features/common-elements';
import { LoanTable } from 'features/drilldown/LoanDatatable/LoanTable';
import {
  PortfolioFilterConfig,
  useFilterConfig,
} from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { styled } from 'style/ORSNNTheme';
import { DownloadDocumentButton } from 'ui-kit';

import { gql, useQuery } from '@apollo/client';

import {
  AssetClass,
  ParentType,
  SortableField,
  SortDirection,
} from '__generated__/globalTypes';

import { CardCommonProps, CardWrapper } from './CardWrapper';
import { GET_DEAL_LOANS_QUERY } from './gql/inquiryQueries';
import {
  GetDealLoanSpreadsheetUrl,
  GetDealLoanSpreadsheetUrlVariables,
} from '../DealOverview/__generated__/GetDealLoanSpreadsheetUrl';
import { GET_DEAL_LOAN_SPREADSHEET_URL } from '../DealOverview/fragments';
import {
  GetDealLoans,
  GetDealLoansVariables,
} from './gql/__generated__/GetDealLoans';

const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const LoansCardFragments = {
  TimelineCard: gql`
    fragment LoansCard on TimelineCard {
      ... on LoansCard {
        assetClass: asset_class
      }
    }
  `,
};

const useLoansCard = (props: { dealId: string; assetClass: AssetClass }) => {
  const [filters, setFilters] = useState<FilterValue<PortfolioFilterConfig>[]>(
    [],
  );
  const [sortings, setSortings] = useState<
    { id: SortableField; desc: boolean }[]
  >([]);

  const gqlAssetClass = props.assetClass;
  const portfolioFiltersConfig = useFilterConfig(gqlAssetClass, {
    parentType: ParentType.DEAL,
    parentId: props.dealId,
  });
  const queryFilters = portfolioFiltersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        filters,
        portfolioFiltersConfig,
      )
    : [];

  const { data, previousData, loading } = useQuery<
    GetDealLoans,
    GetDealLoansVariables
  >(GET_DEAL_LOANS_QUERY, {
    variables: {
      id: props.dealId,
      sort: {
        sort_direction: sortings[0]?.desc
          ? SortDirection.desc
          : SortDirection.asc,
        sort_field: sortings[0]?.id,
      },
      pagination: {},
      filters: queryFilters,
    },
    //TODO: understand why it doesn't work without this
    fetchPolicy: 'no-cache',
  });

  // fix reloading issue: https://orsnn.atlassian.net/browse/ORSNN-953
  // loan_spreadsheet_url will be queried separately so it will update the Deal cache, but the next getDeal query will
  // not get a new loans_spreadsheet_url so it won't re-update the Deal cache
  const { data: dealDataLoanSpreadsheetUrl } = useQuery<
    GetDealLoanSpreadsheetUrl,
    GetDealLoanSpreadsheetUrlVariables
  >(GET_DEAL_LOAN_SPREADSHEET_URL, {
    variables: {
      id: props.dealId,
    },
  });
  const downloadUrl = dealDataLoanSpreadsheetUrl?.deal?.loans_spreadsheet_url;

  const dataToUse = data || previousData;
  const loans = dataToUse?.deal?.loans;

  return {
    filters,
    loans,
    gqlAssetClass,
    portfolioFiltersConfig,
    setFilters,
    downloadUrl,
    loading,
    sortings,
    setSortings,
  };
};

type LoansCardProps = { dealId: string } & CardCommonProps;

function LoansCard(props: LoansCardProps) {
  return (
    <CardWrapper {...props} name="loans">
      {props.assetClass != null ? (
        <LoansCardBody {...props} assetClass={props.assetClass} />
      ) : null}
    </CardWrapper>
  );
}

const LoansCardBody = (props: { dealId: string; assetClass: AssetClass }) => {
  const {
    sortings,
    setSortings,
    downloadUrl,
    filters,
    loans,
    gqlAssetClass,
    portfolioFiltersConfig,
    setFilters,
    loading,
  } = useLoansCard(props);

  const tableBody =
    loans && gqlAssetClass && portfolioFiltersConfig ? (
      <>
        <FiltersRow>
          <PillFilters
            filtersConfig={portfolioFiltersConfig}
            currentFilters={filters}
            setCurrentFilters={setFilters}
          />
          {downloadUrl && (
            <DownloadDocumentButton
              downloadUrl={downloadUrl}
              fileType="excel"
              tooltipText="Download Excel"
              tooltipPosition="left"
              className="mr-4"
            />
          )}
        </FiltersRow>
        <div style={{ height: '400px' }}>
          <LoanTable
            data={loans ?? []}
            assetClass={gqlAssetClass}
            strings={{ empty: 'No Loans Found' }}
            sorting={{ state: sortings, onSortingChanged: setSortings }}
            withListingLink={false}
          />
        </div>
      </>
    ) : null;

  return (
    <>
      {tableBody}
      <Spinner loading={loading} positionRelative={!loans} />
    </>
  );
};

export { LoansCard, LoansCardBody, LoansCardFragments };
