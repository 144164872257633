import React from 'react';

import { Tooltip } from 'react-tooltip';

interface IconButtonProps {
  icon: React.ReactNode;
  onClick?: () => void;
  tooltipText?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  ariaLabel?: string;
  variant?: 'default' | 'icon-only';
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  tooltipText,
  tooltipPosition = 'top',
  className,
  ariaLabel = 'icon button',
  variant = 'default',
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <button
      type="button"
      aria-label={ariaLabel}
      data-tooltip-id={tooltipText ? 'icon-button-tooltip' : undefined}
      className={`
        ${variant === 'icon-only' ? '' : 'rounded-md border border-transparent-0 p-2 hover:border-pink-500 hover:bg-gray-900'}
        bg-transparent flex cursor-pointer items-center justify-center transition-all duration-300 ${className}`}
      onClick={handleClick}
    >
      {icon}
      {tooltipText && (
        <Tooltip
          id="icon-button-tooltip"
          className="max-w-[300px]"
          place={tooltipPosition}
        >
          {tooltipText}
        </Tooltip>
      )}
    </button>
  );
};
