import React, { useMemo, useState } from 'react';

import { BaseButton } from 'common-ui';
import { GetFilterBounds_deal_performance_summary } from 'features/core/filter/__generated__/GetFilterBounds';
import { UseCarveFunctions } from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/hooks';
import {
  CarveAction,
  CarveSpecification,
} from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/types';
import { CarveSummary } from 'features/deals/DealStages/EventActionCards/CarveDiffTable/__generated__/CarveSummary';
import { CarveActions, CarveCardContainer, CarveName, Subheader } from 'ui-kit';

import { GetCarvesVariables } from 'query/__generated__/GetCarves';
import { GET_CARVES } from 'query/getCarves';

import { CarveCard, CarveDetailsTable } from './CarveCard';
import { CreateCarveForm, Criteria } from './CreateCarveForm';
import CreateCarveQuestion from './CreateCarveQuestion';
import { criteriaToFilters } from './criteriaToFilterMapper';

interface TradeCarveManagerProps {
  dealId: string;
  carveSpecifications: CarveSpecification[];
  originalOffer: CarveSummary | null;
  filterBounds: GetFilterBounds_deal_performance_summary | undefined;
  onCarveAction: (action: CarveAction) => void;
  carveFunctions: UseCarveFunctions;
  onNoCarve: () => void;
}

const TradeCarveManager: React.FC<TradeCarveManagerProps> = ({
  dealId,
  carveSpecifications,
  originalOffer,
  filterBounds,
  onCarveAction,
  onNoCarve,
  carveFunctions,
}) => {
  const [isInCreateCarvesMode, setIsInCreateCarvesMode] = useState<boolean>(
    carveSpecifications != null && carveSpecifications.length > 0,
  );

  const [selectedCarveId, setSelectedCarveId] = useState<string>();

  const selectedCarve = useMemo(
    () => carveSpecifications.find((carve) => carve.id === selectedCarveId),
    [carveSpecifications, selectedCarveId],
  );

  const { deleteDealCarve, deleteLoading, createDealCarve, createLoading } =
    carveFunctions;

  const handleCreateNewCarve = () => {
    const newId = '' + Date.now();
    onCarveAction({
      type: 'create-draft-carve',
      id: newId,
      name: '',
      criteria: [],
    });
    setSelectedCarveId(newId);
  };

  const getCarvesVariables: GetCarvesVariables = useMemo(
    () => ({
      deal_id: dealId,
    }),
    [dealId],
  );

  const handleSaveCarve = async (carveName: string, criteria: Criteria[]) => {
    const filters = criteriaToFilters(criteria);
    await createDealCarve({
      variables: {
        input: {
          deal_id: dealId,
          name: carveName,
          carve: filters,
        },
      },
      refetchQueries: [
        {
          query: GET_CARVES,
          variables: getCarvesVariables,
        },
      ],
      onCompleted: ({ createDealCarve }) => {
        onCarveAction({
          type: 'save-carve',
          id: selectedCarve?.id || '',
          name: carveName,
          newId: createDealCarve.id,
        });
        setSelectedCarveId(createDealCarve.id);
      },
    });
  };

  const handleCriteriaChanged = (criteria: Criteria[]) => {
    if (selectedCarve === undefined) return;
    onCarveAction({
      type: 'update-carve',
      carveSpec: { ...selectedCarve, criteria },
    });
  };

  const handleRemoveCarve = async (carveId: string | undefined) => {
    if (carveId === undefined) return;
    await deleteDealCarve({
      variables: {
        input: {
          id: carveId,
        },
      },
      refetchQueries: [
        {
          query: GET_CARVES,
          variables: getCarvesVariables,
        },
      ],
      onCompleted: () => {
        setSelectedCarveId(undefined);
      },
    });
  };

  if (originalOffer === null) {
    return null;
  }

  const onClickToNextCard = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onNoCarve();
  };

  const canClickNext =
    carveSpecifications != null &&
    carveSpecifications.length > 0 &&
    carveSpecifications.some((carve) => !carve.isDraft);

  return (
    <div className="text-slate-200">
      <Subheader>
        Create and save pool carves from the offering. Select saved carves for
        pricing.
      </Subheader>
      {isInCreateCarvesMode ? (
        <>
          <div className="mt-4 flex flex-nowrap gap-x-3">
            <CreateCarveForm
              selectedCarve={selectedCarve}
              filterBounds={filterBounds}
              onSaveCarve={handleSaveCarve}
              disabled={!selectedCarve || createLoading}
              onCriteriaChanged={handleCriteriaChanged}
              isUpdating={selectedCarve?.isUpdating || false}
            />
            <div className="max-w-[calc(100%-400px)]">
              <CarveActions>
                <BaseButton
                  onClick={handleCreateNewCarve}
                  label={'create new carve'}
                >
                  Create New Carve
                </BaseButton>
                <BaseButton
                  type="secondary"
                  disabled={selectedCarveId === undefined || deleteLoading}
                  onClick={() => handleRemoveCarve(selectedCarveId)}
                  label={'Remove carve'}
                >
                  Remove Carve
                </BaseButton>
              </CarveActions>
              <div className="flex w-full flex-wrap gap-2">
                <CarveCardContainer highlight={true}>
                  <CarveName>Original offer</CarveName>
                  <CarveDetailsTable carveSummary={originalOffer} />
                </CarveCardContainer>

                {carveSpecifications?.map((carve) => {
                  return (
                    <CarveCardContainer
                      highlight={carve.id === selectedCarveId}
                      key={carve.id}
                    >
                      <CarveCard
                        carve={carve}
                        isSelected={carve.id === selectedCarveId}
                        onCarveSelected={() => setSelectedCarveId(carve.id)}
                      />
                    </CarveCardContainer>
                  );
                })}
              </div>
            </div>
          </div>
          <BaseButton
            label={'Next'}
            disabled={!canClickNext}
            onClick={onClickToNextCard}
            type="primary"
          >
            Next
          </BaseButton>
        </>
      ) : (
        <CreateCarveQuestion
          setCreateCarve={setIsInCreateCarvesMode}
          onNoCarve={onClickToNextCard}
        />
      )}
    </div>
  );
};

export type { TradeCarveManagerProps };

export { TradeCarveManager };
