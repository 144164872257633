import { useState, useMemo, useEffect } from 'react';

import { Subheader, Tab, Tabs } from 'ui-kit';

import { DealRole } from '__generated__/globalTypes';

import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';
import { Note } from './DealDocumentTypes';
import DiligencePreVsPost from './DiligencePreVsPost';
import { ReviewDiligenceProps, ReviewDiligenceTab } from './ReviewDiligenceTab';
import { SelectLoansProps, SelectLoansTab } from './SelectLoansTab';

export type LoanNotesMap = {
  [loanId: string]: Note[];
};
export interface DiligenceProps {
  selectLoansProps: SelectLoansProps;
  reviewDiligenceProps: ReviewDiligenceProps;
  role: DealRole;
  originalPoolSummary: DiligenceCard_DiligenceCard['original_pool_summary'];
  acceptedBidSummary: DiligenceCard_DiligenceCard['accepted_bid_summary'];
  postDiligenceSummary: DiligenceCard_DiligenceCard['post_diligence_summary'];
  diligenceReports: DiligenceCard_DiligenceCard['diligence_reports'];
  dealId: string;
  isComplete: boolean;
  should_show_summaries_tab: boolean;
  buyerAccepted: boolean;
  sellerAccepted: boolean;
}

export const Diligence = ({
  selectLoansProps,
  reviewDiligenceProps,
  role,
  originalPoolSummary,
  acceptedBidSummary,
  postDiligenceSummary,
  diligenceReports,
  dealId,
  isComplete,
  should_show_summaries_tab,
  buyerAccepted,
  sellerAccepted,
}: DiligenceProps) => {
  const isSeller = role === DealRole.SELLER;
  const [activeTab, setActiveTab] = useState(0);

  const switchToReviewDiligence = () => {
    setActiveTab(1);
  };

  const memoizedReviewDiligenceProps = useMemo(
    () => ({
      ...reviewDiligenceProps,
    }),
    [reviewDiligenceProps],
  );

  const determineActiveTab = () => {
    if (should_show_summaries_tab) {
      return isSeller ? 1 : isComplete ? 1 : 2;
    }
    if ((memoizedReviewDiligenceProps.totalDiligenceItems ?? 0) > 0) {
      return isSeller ? 0 : 1;
    }
    return 0;
  };

  useEffect(() => {
    setActiveTab(determineActiveTab());
  }, [
    memoizedReviewDiligenceProps.totalDiligenceItems,
    isSeller,
    should_show_summaries_tab,
  ]);

  const renderContent = () => {
    return (
      <>
        <Subheader>Manage diligence</Subheader>
        <Tabs activeTab={activeTab} onTabChange={setActiveTab}>
          {!isSeller && !isComplete && (
            <Tab label="Select Loans">
              <SelectLoansTab
                {...selectLoansProps}
                switchToReviewDiligence={switchToReviewDiligence}
              />
            </Tab>
          )}
          <Tab label="Review Diligence">
            <ReviewDiligenceTab {...memoizedReviewDiligenceProps} />
          </Tab>
          {should_show_summaries_tab && (
            <Tab label="Pre vs Post Diligence Deal Parameters">
              <DiligencePreVsPost
                originalPoolSummary={originalPoolSummary}
                acceptedBidSummary={acceptedBidSummary}
                postDiligenceSummary={postDiligenceSummary}
                diligenceReports={diligenceReports}
                dealId={dealId}
                isComplete={isComplete}
                buyerAccepted={buyerAccepted}
                sellerAccepted={sellerAccepted}
                role={role}
              />
            </Tab>
          )}
        </Tabs>
      </>
    );
  };

  return (
    <div className="text-slate-200">
      <div className="flex flex-col gap-6">{renderContent()}</div>
    </div>
  );
};
