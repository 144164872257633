import { ReactNode } from 'react';

import { AssetClassToLoanType } from 'app-level/config/assetClassTypes';
import { formatCurrency } from 'configs/columns';
import { Link } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';

import {
  LoanField,
  SortableField,
  AssetClass as GqlAssetClass,
} from '__generated__/globalTypes';

import { LoanDatatableLoan as Loan } from './__generated__/LoanDatatableLoan';
import { ButtonField } from './ButtonField';

const ListingLink = styled(Link)`
  color: ${(props) => props.theme.color.brandMainPink};
  &:hover {
    color: ${(props) => props.theme.color.darkPrimaryLabel};
    text-decoration: underline solid
      ${(props) => props.theme.color.darkPrimaryLabel};
  }
`;

type LoanColConfig<T extends Loan, K extends keyof T> = {
  string: string;
  sortSelector?: SortableField;
  numeric?: boolean;
  display: (value: T[K], other: object) => string | ReactNode;
};

export type LoanColumnsConfig<T extends Loan> = {
  [field in keyof T]?: LoanColConfig<T, field>;
};

const sharedColumns: { [field in keyof Loan]?: LoanColConfig<Loan, field> } = {
  [LoanField.account_id]: {
    string: `LOAN ID`,
    sortSelector: SortableField.account_id,
    numeric: false,
    display: (value, { onClick }: { onClick?: () => void }) =>
      onClick ? <ButtonField label={value} onClick={onClick} /> : value || '',
  },
  listing: {
    string: `POOL`,
    display: (value) => {
      if (value?.name && value?.id) {
        return (
          <ListingLink to={`/marketplace/listing/${value.id}`}>
            {value.provided_name}
          </ListingLink>
        );
      } else if (value?.provided_name) {
        return value.provided_name;
      }
      return '-';
    },
  },
  [LoanField.original_balance_cents]: {
    string: `ORIG. BAL`,
    sortSelector: SortableField.original_balance_cents,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  [LoanField.current_balance_cents]: {
    string: `UPB`,
    sortSelector: SortableField.current_balance_cents,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  [LoanField.age_months]: {
    string: `LOAN AGE`,
    sortSelector: SortableField.age_months,
    numeric: true,
    display: (value) => value?.toLocaleString() ?? `-`,
  },
  [LoanField.remaining_loan_terms_months]: {
    string: `MATURITY`,
    sortSelector: SortableField.remaining_loan_terms_months,
    numeric: true,
    display: (value) => value?.toLocaleString() ?? `-`,
  },
  [LoanField.interest_rate]: {
    string: 'INTEREST RATE',
    sortSelector: SortableField.interest_rate,
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(3)}%`,
  },
  [LoanField.borrower_credit_score]: {
    string: 'CREDIT SCORE',
    sortSelector: SortableField.borrower_credit_score,
    numeric: true,
    display: (value) => value?.toString() ?? '-',
  },
  [LoanField.product]: {
    string: 'PRODUCT',
    sortSelector: SortableField.product,
    display: (value) => value || null,
  },
};

type HomeLoan = AssetClassToLoanType[GqlAssetClass.HOME];
export const homeColumnsConfig: {
  [field in keyof HomeLoan]?: LoanColConfig<HomeLoan, field>;
} = {
  ...sharedColumns,
  [LoanField.ltv]: {
    string: 'LTV',
    sortSelector: SortableField.ltv,
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.dti]: {
    string: 'DTI',
    sortSelector: SortableField.dti,
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.state]: {
    string: 'STATE',
    display: (value) => value || null,
  },
  [LoanField.occupancy]: {
    string: 'OCCUPANCY',
    display: (value) => value || null,
  },
  [LoanField.purpose]: {
    string: 'PURPOSE',
    display: (value) => value || null,
  },
  [LoanField.cra]: {
    string: 'CRA',
    numeric: false,
    display: (value) => (value ? 'True' : 'False'),
  },
};
type HomeARMLoan = AssetClassToLoanType['HOME_ARM'];

export const homeARMColumnsConfig: {
  [field in keyof HomeLoan]?: LoanColConfig<HomeLoan, field>;
} = {
  ...homeColumnsConfig,
  [LoanField.irap_months]: {
    string: 'IRAP',
    numeric: true,
    display: (value) => value || null,
  },
  [LoanField.rap_months]: {
    string: 'RAP',
    numeric: true,
    display: (value) => value || null,
  },
  [LoanField.periodic_cap_rate]: {
    string: 'Periodic Cap',
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.lifetime_cap_rate]: {
    string: 'Lifetime Cap',
    numeric: false,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.lifetime_floor_rate]: {
    string: 'Lifetime Cap',
    numeric: false,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.interest_only]: {
    string: 'Interest Only',
    display: (value) => (value ? 'True' : 'False'),
  },
  [LoanField.io_period_months]: {
    string: 'I/O Term',
    numeric: true,
    display: (value) => value || null,
  },
  [LoanField.amortization_term_months]: {
    string: 'Amortization Term',
    numeric: true,
    display: (value) => value || null,
  },
  [LoanField.balloon_term_months]: {
    string: 'Balloon Term',
    numeric: true,
    display: (value) => value || null,
  },
  [LoanField.arm_margin]: {
    string: 'ARM Margin',
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.arm_index]: {
    string: 'ARM Index',
    display: (value) => value || null,
  },
  [LoanField.arm_index_rate]: {
    string: 'ARM Index Rate',
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
};

type AutoLoan = AssetClassToLoanType[GqlAssetClass.AUTO];
export const autoColumnsConfig: {
  [field in keyof AutoLoan]?: LoanColConfig<AutoLoan, field>;
} = {
  ...sharedColumns,
  [LoanField.ltv]: {
    string: 'LTV',
    sortSelector: SortableField.ltv,
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.dti]: {
    string: 'DTI',
    sortSelector: SortableField.dti,
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.state]: {
    string: 'STATE',
    display: (value) => value || null,
  },
  [LoanField.auto_original_value_cents]: {
    string: `O VAL`,
    sortSelector: SortableField.auto_original_value_cents,
    numeric: true,
    display: (value) => formatCurrency(value),
  },
  [LoanField.auto_vin]: {
    string: `VIN`,
    display: (value) => value || null,
  },
};

type UnsecPersonalLoan = AssetClassToLoanType[GqlAssetClass.UNSECPERSONAL];
export const unsecperosnalColumnsConfig: {
  [field in keyof UnsecPersonalLoan]?: LoanColConfig<UnsecPersonalLoan, field>;
} = {
  ...sharedColumns,
  [LoanField.dti]: {
    string: 'DTI',
    sortSelector: SortableField.dti,
    numeric: true,
    display: (value) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [LoanField.purpose]: {
    string: 'PURPOSE',
    display: (value) => value || null,
  },
};

export const unsecpersonalColumnsOrder: (keyof UnsecPersonalLoan)[] = [
  LoanField.account_id,
  'listing',
  LoanField.original_balance_cents,
  LoanField.current_balance_cents,
  LoanField.age_months,
  LoanField.remaining_loan_terms_months,
  LoanField.interest_rate,
  LoanField.borrower_credit_score,
  LoanField.dti,
  LoanField.purpose,
  LoanField.product,
];

export const homeColumnsOrder: (keyof HomeLoan)[] = [
  LoanField.account_id,
  'listing',
  LoanField.original_balance_cents,
  LoanField.current_balance_cents,
  LoanField.state,
  LoanField.age_months,
  LoanField.remaining_loan_terms_months,
  LoanField.interest_rate,
  LoanField.borrower_credit_score,
  LoanField.ltv,
  LoanField.dti,
  LoanField.product,
  LoanField.occupancy,
  LoanField.purpose,
  LoanField.cra,
];

export const homeARMColumnsOrder: (keyof HomeARMLoan)[] = [
  ...homeColumnsOrder,
  LoanField.irap_months,
  LoanField.rap_months,
  LoanField.periodic_cap_rate,
  LoanField.lifetime_cap_rate,
  LoanField.lifetime_floor_rate,
  LoanField.interest_only,
  LoanField.io_period_months,
  LoanField.amortization_term_months,
  LoanField.balloon_term_months,
  LoanField.arm_margin,
  LoanField.arm_index,
  LoanField.arm_index_rate,
];
export const autoColumnsOrder: (keyof AutoLoan)[] = [
  LoanField.account_id,
  'listing',
  LoanField.state,
  LoanField.original_balance_cents,
  LoanField.current_balance_cents,
  LoanField.age_months,
  LoanField.remaining_loan_terms_months,
  LoanField.interest_rate,
  LoanField.borrower_credit_score,
  LoanField.ltv,
  LoanField.dti,
  LoanField.product,
  LoanField.auto_original_value_cents,
  LoanField.auto_vin,
];

export type LoanKeys = keyof (HomeLoan & AutoLoan);

export const assetClassToColumnsConfig: {
  [key in keyof AssetClassToLoanType]: {
    order: (keyof AssetClassToLoanType[key])[];
    config: LoanColumnsConfig<AssetClassToLoanType[key]>;
  } | null;
} = {
  [GqlAssetClass.HOME]: { config: homeColumnsConfig, order: homeColumnsOrder },
  HOME_ARM: { config: homeColumnsConfig, order: homeColumnsOrder },
  [GqlAssetClass.AUTO]: { config: autoColumnsConfig, order: autoColumnsOrder },
  [GqlAssetClass.UNSECPERSONAL]: {
    config: unsecperosnalColumnsConfig,
    order: unsecpersonalColumnsOrder,
  },
  [GqlAssetClass.AIRCRAFT]: null,
  [GqlAssetClass.BOAT]: null,
  [GqlAssetClass.CRE]: null,
  [GqlAssetClass.MARINE]: null,
  [GqlAssetClass.MBL]: null,
  [GqlAssetClass.MOTORCYCLE]: null,
  [GqlAssetClass.REVOLVING]: null,
  [GqlAssetClass.RV]: null,
  [GqlAssetClass.SOLAR]: null,
  [GqlAssetClass.WAREHOUSE]: null,
};
