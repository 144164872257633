/**
 * Design here: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=5089-146902&mode=design&t=9HZlSXWSxwj25KKG-0
 */
import { FC } from 'react';

import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import { gql } from '@apollo/client';

import {
  ExecutionType,
  OriginationType,
  PerformanceClass,
  ServicingPreference,
  TimelineStatus,
} from '__generated__/globalTypes';

import { StatusTrackerDeal } from './__generated__/StatusTrackerDeal';
import { formatCentsToDollars } from './BiddingAndPricing/formatting';
import { useDealRoomContext } from './DealRoomContext';
import { InfoBadge } from './InfoBadge';
import mainTaskComplete from '../../../assets/svgs/main-task-complete.svg';
import mainTaskNotStarted from '../../../assets/svgs/main-task-not-started.svg';
import mainTaskPending from '../../../assets/svgs/main-task-pending.svg';
import subTaskComplete from '../../../assets/svgs/subtask-complete.svg';

const StatusTrackerFragments = {
  deal: gql`
    fragment StatusTrackerDeal on Deal {
      id
      state {
        status
        label
        tooltip
        blocker
      }
      timeline {
        title
        subject
        status
        calls_to_action {
          title
          card {
            __typename
          }
        }
      }
      listing {
        id
        name
        provided_name
        __typename
        ... on PublicListing {
          execution_type
          servicing_preference
          minimum_carve_cents
          performance_class
          origination_type
        }
        ... on UserCompanyListing {
          execution_type
          servicingPref: servicing_preference # graphql feels weird about the names being same with different type
          minimum_carve_cents
          performance_class
          origination_type
        }
      }
    }
  `,
};

const TaskBase = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-color: #171717;
  position: absolute;
  left: 5px;
  width: 26px;
  height: 26px;

  top: -5px;
`;

const TaskComplete = styled(TaskBase)`
  background-image: url(${mainTaskComplete});
`;

const TaskInProgress = styled(TaskBase)`
  background-image: url(${mainTaskPending});
`;

const TaskNotStarted = styled(TaskBase)`
  background-image: url(${mainTaskNotStarted});
`;

const formatOriginationType = (originationType: OriginationType) => {
  switch (originationType) {
    case OriginationType.DIRECT:
      return 'Direct';
    case OriginationType.INDIRECT:
      return 'Indirect';
    case OriginationType.INDIRECT_FINTECH:
      return 'Indirect Fintech';
    case OriginationType.MIXED:
      return 'Mixed';
    case OriginationType.N_A:
      return 'N/A';
  }
};

const formatExecutionType = (executionType: ExecutionType) => {
  switch (executionType) {
    case ExecutionType.ALL_OR_NOTHING:
      return 'AoN';
    case ExecutionType.BUYER_CHOICE:
      return 'Buyer Choice';
  }
};

const formatServicingPreference = (
  servicingPreference: ServicingPreference,
) => {
  switch (servicingPreference) {
    case ServicingPreference.NONE:
      return 'No Preference';
    case ServicingPreference.RELEASED:
      return 'Released';
    case ServicingPreference.RETAINED:
      return 'Retained';
  }
};

const formatPerformanceClass = (performanceClass: PerformanceClass) => {
  switch (performanceClass) {
    case PerformanceClass.NON_PERFORMING:
      return 'Non-Performing';
    case PerformanceClass.PERFORMING:
      return 'Performing';
    case PerformanceClass.SUB_PERFORMING:
      return 'Sub-Performing';
    case PerformanceClass.RE_PERFORMING:
      return 'Re-Performing';
  }
};

type StatusTrackerProps = {
  deal: StatusTrackerDeal | null;
};

const StatusTracker: FC<StatusTrackerProps> = (props: StatusTrackerProps) => {
  const { deal } = props;
  // TODO: use same query to render the cards and these subtasks, then useRef to scroll to the correct card
  const { setCurrentCard } = useDealRoomContext();

  const listing = deal?.listing;
  if (!listing) {
    return <></>;
  }

  const servicing =
    'servicing_preference' in listing
      ? listing.servicing_preference
      : listing.servicingPref;

  const calculateActionIndex = (
    stageIndex: number,
    callToActionIndex: number,
  ) => {
    let previousActionsCount = 0;
    for (let i = 0; i < stageIndex; i++) {
      previousActionsCount += deal?.timeline[i].calls_to_action.length || 0;
    }

    return previousActionsCount + callToActionIndex;
  };

  return (
    <div className="relative h-full overflow-auto border border-solid border-[#2b2b2b] bg-background-canvas p-[2px] px-5 pb-5 pt-[18px]">
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="text-[18px] text-[#f2f2f2]">
            {listing.provided_name}
          </div>
          <span
            className="text-[14px] text-green-400"
            data-tooltip-id={deal?.listing.name}
          >
            {deal?.state.label}
          </span>
        </div>
        {(listing.__typename === 'PublicListing' ||
          listing.__typename === 'UserCompanyListing') && (
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1">
                <div className="text-[12px] text-[#909199]">
                  Origination Type
                </div>
                <InfoBadge
                  label={formatOriginationType(listing.origination_type)}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1">
                <div className="text-[12px] text-[#909199]">Execution</div>
                <InfoBadge
                  label={formatExecutionType(listing.execution_type)}
                />
              </div>
            </div>
            {servicing && (
              <div className="flex flex-col">
                <div className="flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1">
                  <div className="text-[12px] text-[#909199]">Servicing</div>
                  <InfoBadge label={formatServicingPreference(servicing)} />
                </div>
              </div>
            )}
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1">
                <div className="text-[12px] text-[#909199]">Performance</div>
                <InfoBadge
                  label={formatPerformanceClass(listing.performance_class)}
                />
              </div>
            </div>
            {listing.minimum_carve_cents ? (
              <div className="flex flex-col">
                <div className="flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1">
                  <div className="text-[12px] text-[#909199]">
                    Minimum Carve
                  </div>
                  <InfoBadge
                    label={formatCentsToDollars(listing.minimum_carve_cents)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div className="relative overflow-auto">
        <div className="absolute bottom-0 left-[17px] top-4 w-[1px] bg-[#2b2b2b]" />
        {deal?.timeline.map((stage, stageIndex) => {
          return (
            <div key={`${stage.title}-${stageIndex}`}>
              <div className="relative my-5 pl-10">
                {stage.status === TimelineStatus.COMPLETE ? (
                  <TaskComplete />
                ) : stage.status === TimelineStatus.PENDING ? (
                  <TaskNotStarted />
                ) : (
                  <TaskInProgress />
                )}
                <div>
                  <span className="text-[14px] text-[#909199]">
                    {stage.title}
                  </span>
                  {stage.status && stage.status !== TimelineStatus.PENDING && (
                    <span className="ml-5 border-l-[1.5px] border-[#ffcf74] pl-1 font-['PT_Sans_Narrow'] text-[12px] font-normal text-[#ffcf74]">
                      {stage.status}
                    </span>
                  )}
                </div>
                <div className="text-[12px] text-[#909199]">
                  {stage.subject}
                </div>
              </div>
              {stage.calls_to_action?.map((cta, callToActionIndex) => (
                <div
                  key={`${cta.title}-${stageIndex + callToActionIndex}`}
                  className="relative my-5 pl-10 text-[12px] text-[#bbc5d7]"
                >
                  <div
                    className="absolute left-[12px] top-[1px] h-[10px] w-[10px] bg-background-canvas bg-center bg-no-repeat"
                    style={{ backgroundImage: `url(${subTaskComplete})` }}
                  />
                  <span
                    onClick={() => {
                      const actionIndex = calculateActionIndex(
                        stageIndex,
                        callToActionIndex,
                      );
                      if (cta.card.__typename !== 'PlaceholderCard') {
                        const ctaKey =
                          cta.title === 'Bidding and Pricing'
                            ? cta.title
                            : `${cta.title}-${actionIndex}`;

                        setCurrentCard(ctaKey);
                      }
                    }}
                    className={`underline ${cta.card.__typename === 'PlaceholderCard' ? 'not-allowed cursor-not-allowed opacity-50' : 'pointer cursor-pointer'}`}
                  >
                    {cta.title}
                  </span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <Tooltip id={deal?.listing.name} className="max-w-full">
        {deal?.state.tooltip}
      </Tooltip>
    </div>
  );
};

export default StatusTracker;
export { StatusTrackerFragments };
