import { useQuery } from '@apollo/client';

import {
  GetDiligenceNotes,
  GetDiligenceNotesVariables,
} from 'query/__generated__/GetDiligenceNotes';
import { GET_DILIGENCE_NOTES } from 'query/diligence';

export const useDiligenceNotes = (diligenceId: string) => {
  const { data, loading, error } = useQuery<
    GetDiligenceNotes,
    GetDiligenceNotesVariables
  >(GET_DILIGENCE_NOTES, {
    variables: { diligence_id: diligenceId },
    skip: !diligenceId,
  });

  const hasNotes =
    !!data?.diligenceNotes?.entities && data.diligenceNotes.entities.length > 0;

  return { hasNotes, loading, error };
};
