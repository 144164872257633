import * as XLSX from 'xlsx';

/**
 * Generates and downloads an Excel file from JSON data.
 *
 * @param data - The JSON data to convert into an Excel file.
 * @param sheetName - The name of the Excel sheet.
 * @param fileName - The name of the Excel file to download.
 */
export const downloadExcelFromJson = (
  data: unknown[],
  sheetName: string = 'Sheet1',
  fileName: string = 'ExportedData.xlsx',
): void => {
  if (!data || data.length === 0) {
    console.error('No data available for download.');
    return;
  }

  // Convert JSON data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  // Write workbook to a binary buffer
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  // Create a Blob and trigger a download
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;

  // Trigger the download
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);

  console.log(`${fileName} downloaded successfully.`);
};
