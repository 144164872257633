import React, { useState, useEffect, useRef } from 'react';

import { IconHelp } from '@tabler/icons-react';

export const DraggableHelpButton = () => {
  const [position, setPosition] = useState({ x: 20, y: 20 });
  const [dragging, setDragging] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleDragStart = (e: React.MouseEvent) => {
    setDragging(true);
    e.preventDefault();
  };

  const handleDrag = (e: MouseEvent) => {
    if (dragging) {
      setPosition({
        x: window.innerWidth - e.clientX - 40,
        y: window.innerHeight - e.clientY - 40,
      });
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleButtonClick = () => {
    if (!dragging) {
      if (isExpanded) {
        window.open(
          'https://orsnn.zendesk.com/hc/en-us/requests/new',
          '_blank',
        );
      } else {
        setIsExpanded(true);
      }
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target as Node)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    } else {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [dragging]);

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isExpanded]);

  return (
    <div
      ref={buttonRef}
      className="fixed z-50 cursor-grab"
      style={{
        right: position.x,
        bottom: position.y,
      }}
      onMouseDown={handleDragStart}
    >
      <button
        className={`flex items-center justify-center rounded-full bg-brand-800 text-white shadow-lg transition-all duration-300 hover:bg-brand-900 focus:outline-none focus:ring-4 focus:ring-purple-500`}
        onClick={handleButtonClick}
      >
        {isExpanded && (
          <span className="text-m ml-4 font-medium leading-none text-white md:text-base">
            Get Help
          </span>
        )}
        <div className="rounded-full p-2 transition-colors duration-300">
          <IconHelp
            stroke={2}
            className={`${isExpanded ? 'text-white' : 'text-brand-400'} ${
              isExpanded ? 'h-8 w-8' : 'h-6 w-6 md:h-8 md:w-8'
            }`}
          />
        </div>
      </button>
    </div>
  );
};
