import React from 'react';

interface RadioButtonProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  label?: string;
  size?: 'default' | 'small';
  variant?: 'default' | 'primary' | 'accent';
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  disabled,
  onChange,
  label,
  size = 'default',
  variant = 'default',
}) => {
  const sizeClasses = size === 'small' ? 'h-4 w-4' : 'h-6 w-6';

  const outerCircleClasses = `rounded-full border-2 ${
    disabled
      ? 'border-gray-800 bg-gray-800'
      : variant === 'default'
        ? checked
          ? 'border-white'
          : 'border-white'
        : variant === 'primary'
          ? checked
            ? 'border-pink-400'
            : 'border-pink-400'
          : variant === 'accent'
            ? checked
              ? 'border-pink-500'
              : 'border-pink-500'
            : ''
  }`;

  const innerCircleClasses = `rounded-full ${
    disabled
      ? 'bg-gray-800'
      : variant === 'default'
        ? 'bg-white'
        : variant === 'primary'
          ? 'bg-pink-400'
          : variant === 'accent'
            ? 'bg-pink-500'
            : ''
  }`;
  const labelClasses = `${
    size === 'small' ? 'text-sm' : 'text-base'
  } font-heebo ml-2 ${disabled ? 'text-gray-800' : 'text-white'}`;

  return (
    <div className="flex cursor-pointer items-center">
      <div
        className={`flex items-center justify-center ${sizeClasses} ${outerCircleClasses} transition-all duration-300`}
        onClick={!disabled ? onChange : undefined}
      >
        {checked && (
          <div
            className={`${innerCircleClasses} ${
              size === 'small' ? 'h-2 w-2' : 'h-3 w-3'
            }`}
          ></div>
        )}
      </div>
      {label && <span className={labelClasses}>{label}</span>}
    </div>
  );
};
