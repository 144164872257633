import { useState } from 'react';

import { IconNote } from '@tabler/icons-react';
import { Checkbox, IconButton, RadioButton } from 'ui-kit';

import { DealRole } from '__generated__/globalTypes';

import { GetDealDiligence_deal_diligence_collateral as Collateral } from 'query/__generated__/GetDealDiligence';

import { toCollateralStatus } from './DiligenceController';
import {
  FileDiligenceStatus,
  FileStatusDropdown,
  fromCollateralStatus,
} from './FileStatus';

interface DocumentsTableProps {
  role: DealRole;
  collateral?: Collateral[];
  selectedDocId?: string;
  onDocSelected: (docId: string) => void;
  handleNoteClick: (docId: string) => void;
  onFileStatusSelect?: (
    dealId: string,
    loanId: string,
    fileName: string,
    status: FileDiligenceStatus,
    callback?: () => void,
  ) => void;
  hasNote: (collateralId: string) => boolean;
}

type CollateralWithNonNullID = Collateral & {
  collateralID: string;
  loanID: string;
};

function isCollateralWithNonNullID(
  c: Collateral,
): c is CollateralWithNonNullID {
  return (
    c.collateralID !== null &&
    c.collateralID !== undefined &&
    c.loanID !== null &&
    c.loanID !== undefined
  );
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  role,
  collateral = [],
  selectedDocId,
  onDocSelected,
  handleNoteClick,
  onFileStatusSelect,
  hasNote,
}) => {
  // TODO: temporary solution that should be fixed during controller refactoring
  const [localStatus, setLocalStatus] = useState<
    Record<string, FileDiligenceStatus>
  >({});

  return (
    <div className="h-60 overflow-y-auto rounded-md border border-gray-900 bg-slate-900">
      {collateral && collateral.length === 0 && (
        <div className="flex h-full w-full items-center justify-center font-pt-mono text-xl text-slate-400">
          No documents found
        </div>
      )}
      {collateral && collateral.length > 0 && (
        <table className="w-full border-collapse text-slate-400">
          <thead className="text-left text-lg">
            <tr>
              <th className="px-3 py-2 font-light"></th>
              <th className="px-3 py-2 font-light"></th>
              <th className="px-3 py-2 font-light">Index</th>
              <th className="px-3 py-2 font-light">Name</th>
              <th className="px-3 py-2 font-light">Status</th>
            </tr>
          </thead>
          <tbody>
            {collateral.filter(isCollateralWithNonNullID).map((doc, idx) => {
              const isSelected = selectedDocId === doc.collateralID;
              const status =
                localStatus[doc.collateralID] !== undefined
                  ? toCollateralStatus(localStatus[doc.collateralID])
                  : doc.collateralStatus;

              return (
                <tr
                  key={doc.collateralID}
                  aria-selected={isSelected ? 'true' : 'false'}
                  className={`${
                    isSelected
                      ? 'bg-brand-900 text-white'
                      : 'bg-background-canvas even:bg-background-surface'
                  } cursor-pointer border border-gray-900 hover:bg-purple-700`}
                  onClick={() => onDocSelected(doc.collateralID)}
                >
                  <td className="pl-6 pr-2 font-pt-mono text-[14px]">
                    <RadioButton
                      checked={isSelected}
                      onChange={() => onDocSelected(doc.collateralID)}
                      variant="primary"
                    />
                  </td>
                  <td className="pr-2 font-pt-mono text-[14px]">
                    <IconButton
                      onClick={() => handleNoteClick(doc.collateralID)}
                      icon={
                        <IconNote
                          stroke={2}
                          className={`${hasNote(doc.collateralID) ? 'text-warning-default' : 'text-white'}`}
                        />
                      }
                    />
                  </td>
                  <td className="px-3 font-pt-mono text-[14px]">{idx + 1}</td>
                  <td className="px-3 font-pt-mono text-[14px]">
                    <span className="text-slate-50">{doc.fileName}</span>
                  </td>
                  <td className="px-3 font-pt-mono text-[14px]">
                    {role === DealRole.BUYER && (
                      <FileStatusDropdown
                        value={status}
                        onChange={(option) =>
                          onFileStatusSelect &&
                          onFileStatusSelect(
                            doc.dealID,
                            doc.loanID,
                            doc.fileName,
                            option,
                            () =>
                              setLocalStatus((prev) => ({
                                ...prev,
                                [doc.collateralID]: option,
                              })),
                          )
                        }
                        isInSelectedRow={isSelected}
                      />
                    )}
                    {role === DealRole.SELLER && (
                      <div>{fromCollateralStatus(doc.collateralStatus)}</div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DocumentsTable;
