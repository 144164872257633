import React, { useState } from 'react';

import {
  IconCheckbox,
  IconCircleCheck,
  IconDeviceIpadDown,
} from '@tabler/icons-react';
import { BaseButton } from 'common-ui';
import { ToggleButton } from 'common-ui/bool-inputs/ToggleButton';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { formatCentsToDollars } from 'features/pages/marketplace/BiddingAndPricing/formatting';
import toast from 'react-hot-toast';
import {
  CarveCardContainer,
  CarveDetailsContainer,
  CarveDetailsRow,
  CarveDetailsLabel,
  CarveDetailsValue,
  CarveSubheader,
  CarveName,
} from 'ui-kit';
import DealButton from 'ui-kit/DealButton/DealButton';

import { useMutation } from '@apollo/client';

import { DealRole } from '__generated__/globalTypes';

import {
  MarkDiligenceAccepted,
  MarkDiligenceAcceptedVariables,
} from 'mutation/__generated__/MarkDiligenceAccepted';
import {
  MarkDiligenceComplete,
  MarkDiligenceCompleteVariables,
} from 'mutation/__generated__/MarkDiligenceComplete';
import { MARK_DILIGENCE_ACCEPTED } from 'mutation/diligenceMutations';
import { MARK_DILIGENCE_COMPLETE } from 'mutation/markDiligenceComplete';

import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';

type DiligenceData = {
  label: string;
  pre: string | number;
  post: string | number;
  base?: string | number;
};

const CONFIG: Record<string, string> = {
  loan_count: '# LOANS',
  current_balance_cents: 'C BAL',
  original_balance_cents: 'O BAL',
  wa_age_months: 'WALA',
  wa_remaining_loan_terms_months: 'WAM',
  wa_coupon: 'GWAC',
  wa_borrower_credit_score: 'FICO',
  wa_ltv: 'LTV',
  wa_dti: 'DTI',
};

type DiligencePreVsPostProps = {
  originalPoolSummary: DiligenceCard_DiligenceCard['original_pool_summary'];
  acceptedBidSummary: DiligenceCard_DiligenceCard['accepted_bid_summary'];
  postDiligenceSummary: DiligenceCard_DiligenceCard['post_diligence_summary'];
  diligenceReports: DiligenceCard_DiligenceCard['diligence_reports'];
  isComplete?: boolean;
  dealId: string;
  buyerAccepted: boolean;
  sellerAccepted: boolean;
  role: DealRole;
};

const DiligencePreVsPost: React.FC<DiligencePreVsPostProps> = ({
  originalPoolSummary,
  acceptedBidSummary,
  postDiligenceSummary,
  diligenceReports,
  isComplete,
  dealId,
  buyerAccepted,
  sellerAccepted,
  role,
}) => {
  const isSeller = role === DealRole.SELLER;
  const initialToggleState = isSeller ? sellerAccepted : buyerAccepted;

  const [isChecked, setIsChecked] = useState(isComplete || initialToggleState);
  const [isMarkingComplete, setIsMarkingComplete] = useState<boolean>(
    isComplete || false,
  );

  const acceptanceStatus = (() => {
    if (isSeller) {
      return buyerAccepted
        ? 'Other party has accepted'
        : 'Other party has yet to accept';
    } else {
      return sellerAccepted
        ? 'Other party has accepted'
        : 'Other party has yet to accept';
    }
  })();
  const statusColor =
    acceptanceStatus === 'Other party has accepted'
      ? 'green-400'
      : 'yellow-400';

  const markCompleteDialog = useDialog();
  const [markDiligenceComplete] = useMutation<
    MarkDiligenceComplete,
    MarkDiligenceCompleteVariables
  >(MARK_DILIGENCE_COMPLETE, {
    onCompleted: () => {
      markCompleteDialog.closeDialog();
      setIsMarkingComplete(false);
    },
  });

  const [markDiligenceAccepted] = useMutation<
    MarkDiligenceAccepted,
    MarkDiligenceAcceptedVariables
  >(MARK_DILIGENCE_ACCEPTED, {
    onCompleted: () => {
      toast.success('Diligence accepted successfully!');
    },
    onError: (error) => {
      toast.error(`Error accepting diligence: ${error.message}`);
    },
  });

  const handleToggleChange = (state: boolean) => {
    if (state) {
      markDiligenceAccepted({
        variables: {
          input: {
            deal_id: dealId,
            accepted: true,
          },
        },
        onCompleted: () => {
          setIsChecked(true);
          toast.success('Diligence set to accepted!');
        },
        onError: (error) => {
          toast.error(`Error accepting diligence: ${error.message}`);
        },
      });
    } else {
      markDiligenceAccepted({
        variables: {
          input: {
            deal_id: dealId,
            accepted: false,
          },
        },
        onCompleted: () => {
          setIsChecked(false);
          toast.success('Diligence set not accepted!');
        },
        onError: (error) => {
          toast.error(`Error declining diligence: ${error.message}`);
        },
      });
      setIsChecked(false);
    }
  };

  const handleMarkCompleteClick = () => {
    setIsMarkingComplete(true);
    const input = {
      variables: {
        input: {
          deal_id: dealId,
        },
      },
    };
    markDiligenceComplete(input);
  };

  const data: DiligenceData[] = [
    {
      label: CONFIG.loan_count,
      base: originalPoolSummary?.loan_count.toLocaleString(),
      pre: acceptedBidSummary?.loan_count.toLocaleString() || '',
      post: postDiligenceSummary?.loan_count.toLocaleString() || '',
    },
    {
      label: CONFIG.current_balance_cents,
      base: formatCentsToDollars(
        Number(originalPoolSummary?.current_balance_cents),
      ),
      pre: formatCentsToDollars(
        Number(acceptedBidSummary?.current_balance_cents),
      ),
      post: formatCentsToDollars(
        Number(postDiligenceSummary?.current_balance_cents),
      ),
    },
    {
      label: CONFIG.original_balance_cents,
      base: formatCentsToDollars(
        Number(originalPoolSummary?.original_balance_cents),
      ),
      pre: formatCentsToDollars(
        Number(acceptedBidSummary?.original_balance_cents),
      ),
      post: formatCentsToDollars(
        Number(postDiligenceSummary?.original_balance_cents),
      ),
    },
    {
      label: CONFIG.wa_age_months,
      base: originalPoolSummary?.wa_age_months.toLocaleString(),
      pre: acceptedBidSummary?.wa_age_months.toLocaleString() || '',
      post: postDiligenceSummary?.wa_age_months.toLocaleString() || '',
    },
    {
      label: CONFIG.wa_remaining_loan_terms_months,
      base: originalPoolSummary?.wa_remaining_loan_terms_months.toLocaleString(),
      pre:
        acceptedBidSummary?.wa_remaining_loan_terms_months.toLocaleString() ||
        '',
      post:
        postDiligenceSummary?.wa_remaining_loan_terms_months.toLocaleString() ||
        '',
    },
    {
      label: CONFIG.wa_coupon,
      base: `${((originalPoolSummary?.wa_coupon ?? 0) * 100).toFixed(3)}%`,
      pre: `${((acceptedBidSummary?.wa_coupon ?? 0) * 100).toFixed(3)}%` || '',
      post:
        `${((postDiligenceSummary?.wa_coupon ?? 0) * 100).toFixed(3)}%` || '',
    },
    {
      label: CONFIG.wa_borrower_credit_score,
      base: originalPoolSummary?.wa_borrower_credit_score.toLocaleString(),
      pre: acceptedBidSummary?.wa_borrower_credit_score.toLocaleString() || '',
      post:
        postDiligenceSummary?.wa_borrower_credit_score.toLocaleString() || '',
    },
    {
      label: CONFIG.wa_ltv,
      base: `${((originalPoolSummary?.wa_ltv ?? 0) * 100).toFixed(2)}%`,
      pre: `${((acceptedBidSummary?.wa_ltv ?? 0) * 100).toFixed(2)}%` || '',
      post: `${((postDiligenceSummary?.wa_ltv ?? 0) * 100).toFixed(2)}%` || '',
    },
    {
      label: CONFIG.wa_dti,
      base: `${((originalPoolSummary?.wa_dti ?? 0) * 100).toFixed(2)}%`,
      pre: `${((acceptedBidSummary?.wa_dti ?? 0) * 100).toFixed(2)}%` || '',
      post: `${((postDiligenceSummary?.wa_dti ?? 0) * 100).toFixed(2)}%` || '',
    },
  ];

  const renderTable = (
    title: string,
    stage: keyof DiligenceData,
    showLabels: boolean,
  ) => (
    <CarveCardContainer highlight={false}>
      <CarveName>{title}</CarveName>
      <CarveSubheader>
        {showLabels ? 'Pre-Carving and Pre-Diligence' : ''}
      </CarveSubheader>
      <CarveDetailsContainer>
        {data.map((item, index) => (
          <CarveDetailsRow key={index}>
            {showLabels && <CarveDetailsLabel>{item.label}</CarveDetailsLabel>}
            <CarveDetailsValue fullWidth={!showLabels}>
              {item[stage]}
            </CarveDetailsValue>
          </CarveDetailsRow>
        ))}
      </CarveDetailsContainer>
    </CarveCardContainer>
  );

  return (
    <div>
      {!isComplete && (
        <div className="flex justify-between rounded-md border border-slate-400 bg-slate-600 px-4 py-2">
          <div>
            <p className="text-sm text-white">
              NEXT: Click that you agree and accept the following parameters,
              below.
            </p>
            <div className="mt-2 flex items-center gap-4">
              <div className="flex gap-2">
                <ToggleButton
                  initialState={isChecked}
                  onChange={handleToggleChange}
                  disabled={isComplete}
                />
                <span
                  className={`text-${isChecked ? 'green-400' : 'accent-default'}`}
                >
                  I Agree and Accept
                </span>
                {isChecked && <IconCircleCheck className="green-yellow-400" />}
              </div>
              <p
                className={`border-l-2 pl-2 text-${statusColor} border-${statusColor} flex items-center gap-2 text-xs`}
              >
                <span>{acceptanceStatus}</span>
                {acceptanceStatus === 'Other party has accepted' && (
                  <IconCircleCheck className="ml-1 text-green-400" />
                )}
              </p>
            </div>
          </div>
          {!isSeller && (
            <div className="flex items-center">
              <DealButton
                label="Mark Diligence Review Complete"
                Icon={<IconCheckbox />}
                iconPosition="right"
                onClick={() => markCompleteDialog.openDialog()}
                disabled={!isChecked}
                variant="success"
              >
                Mark Diligence Review Complete
              </DealButton>
            </div>
          )}
        </div>
      )}
      <div className="mt-4 flex gap-4">
        {renderTable('Entire Offering', 'base', true)}
        {renderTable('Pre-Diligence', 'pre', false)}
        {renderTable('Post-Diligence', 'post', false)}
      </div>
      <div className="mt-4 flex gap-4">
        {diligenceReports?.rejected_loans_csv_url && (
          <a
            href={diligenceReports.rejected_loans_csv_url}
            download
            className="flex items-center px-2 text-accent-default underline"
          >
            <IconDeviceIpadDown size={20} className="text-slate-200" />
            <span className="pl-1">Export List of Loans Removed (.CSV)</span>
          </a>
        )}
        {diligenceReports?.post_diligence_loans_csv_url && (
          <a
            href={diligenceReports.post_diligence_loans_csv_url}
            download
            className="flex items-center px-2 text-accent-default underline"
          >
            <IconDeviceIpadDown size={20} className="text-slate-200" />
            <span className="pl-1">Export Comp (.CSV)</span>
          </a>
        )}
      </div>
      <Dialog dialog={markCompleteDialog}>
        <div className="max-w-[500px]">
          <div>
            <div className="mb-4 text-lg leading-6">
              Mark Diligence Complete
            </div>
            <div className="mb-3 text-xs text-gray-400">
              By marking this diligence complete, you are confirming that you no
              longer need to review any additional diligence or collateral
              material to transact on this deal.
            </div>
          </div>
          <div className="mt-5 flex justify-between">
            <BaseButton
              type="primary"
              label="Accept"
              onClick={handleMarkCompleteClick}
              disabled={isMarkingComplete}
            >
              Accept
            </BaseButton>
            <BaseButton
              type="secondary"
              label="Decline"
              onClick={() => markCompleteDialog.closeDialog()}
            >
              Decline
            </BaseButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DiligencePreVsPost;
