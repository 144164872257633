import React, { useState, useEffect } from 'react';

import { uploadConfig } from 'app-level/config/uploadConfig';
import { Button, KIND } from 'baseui/button';
import { CheckboxWithLabel } from 'common-ui';
import { BaseButton } from 'common-ui/Buttons/BaseButton/BaseButton';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { toast } from 'react-hot-toast';
import { ModalNotStyled } from 'ui-kit';
import { DownloadLink } from 'ui-kit';

import { useQuery, useMutation } from '@apollo/client';

import { FileType } from '__generated__/globalTypes';

import {
  DeleteContract,
  DeleteContractVariables,
} from 'mutation/__generated__/DeleteContract';
import {
  GetContractFileGetUrl,
  GetContractFileGetUrlVariables,
} from 'mutation/__generated__/GetContractFileGetUrl';
import {
  MarkDocumentReadyToSign,
  MarkDocumentReadyToSignVariables,
} from 'mutation/__generated__/MarkDocumentReadyToSign';
import {
  MarkSettlementComplete,
  MarkSettlementCompleteVariables,
} from 'mutation/__generated__/MarkSettlementComplete';
import {
  SaveContractReferenceText,
  SaveContractReferenceTextVariables,
} from 'mutation/__generated__/SaveContractReferenceText';
import {
  SaveContractSignature,
  SaveContractSignatureVariables,
} from 'mutation/__generated__/SaveContractSignature';
import {
  SAVE_CONTRACT_SIGNATURE,
  DELETE_CONTRACT,
  MARK_DOCUMENT_READY_TO_SIGN,
  SAVE_CONTRACT_REFERENCE_TEXT,
  GET_CONTRACT_FILE_GET_URL,
} from 'mutation/contractMutations';
import { MARK_SATTLEMENT_COMPLETE } from 'mutation/markSettlementComplete';

import {
  GetContractsForDeal,
  GetContractsForDealVariables,
  GetContractsForDeal_getContractsForDeal as Contract,
} from 'query/__generated__/GetContractsForDeal';
import GET_CONTRACTS_FOR_DEAL from 'query/getContractsForDeal';

import { SettlementCard_SettlementCard } from './__generated__/SettlementCard';
import ContractReferencesModal from './ContractReferencesModal';
import DocumentRow from './DocumentRow';

interface TransactionDocumentsProps {
  dealId: string;
  role: SettlementCard_SettlementCard['role'];
  isCompleted?: boolean;
}

const TransactionDocuments: React.FC<TransactionDocumentsProps> = ({
  dealId,
  role,
  isCompleted,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [documents, setDocuments] = useState<Contract[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );

  const [saveContractReferenceText] = useMutation<
    SaveContractReferenceText,
    SaveContractReferenceTextVariables
  >(SAVE_CONTRACT_REFERENCE_TEXT);
  const [deleteContract] = useMutation<DeleteContract, DeleteContractVariables>(
    DELETE_CONTRACT,
  );
  const [markDocumentReadyToSign] = useMutation<
    MarkDocumentReadyToSign,
    MarkDocumentReadyToSignVariables
  >(MARK_DOCUMENT_READY_TO_SIGN);
  const [saveContractSignature] = useMutation<
    SaveContractSignature,
    SaveContractSignatureVariables
  >(SAVE_CONTRACT_SIGNATURE);

  const [getContractFileGetUrl] = useMutation<
    GetContractFileGetUrl,
    GetContractFileGetUrlVariables
  >(GET_CONTRACT_FILE_GET_URL);
  const [markSettlementComplete] = useMutation<
    MarkSettlementComplete,
    MarkSettlementCompleteVariables
  >(MARK_SATTLEMENT_COMPLETE);

  const { data } = useQuery<GetContractsForDeal, GetContractsForDealVariables>(
    GET_CONTRACTS_FOR_DEAL,
    {
      variables: { dealId },
      pollInterval: 3000,
    },
  );

  useEffect(() => {
    if (data) {
      const processedDocuments = data.getContractsForDeal.filter(
        (contract): contract is Contract => contract !== null,
      );
      setDocuments(processedDocuments);
    }
  }, [data]);

  const handleFileSuccess = (file?: File) => {
    toast.success(
      <div className="flex flex-col">
        <p>Upload was successful</p>
        <p>Your document '{file?.name}' was successfully uploaded.</p>
      </div>,
      {
        duration: 4000,
        position: 'bottom-right',
      },
    );

    saveContractReferenceText({
      refetchQueries: [
        {
          query: GET_CONTRACTS_FOR_DEAL,
          variables: { dealId },
        },
      ],
      onError: (error) => {
        console.error('Error refetching contract list:', error);
      },
    });
  };

  const handleRowClick = (contractId: string) => {
    setSelectedDocumentId(contractId);
  };

  const handleSubmit = (values: {
    sellerReference: string;
    buyerReference: string;
  }) => {
    if (!selectedDocumentId) return;

    const selectedDocument = documents.find(
      (doc) => doc.contractId === selectedDocumentId,
    );

    if (selectedDocument) {
      saveContractReferenceText({
        variables: {
          dealId,
          contractId: selectedDocument.contractId,
          contractVersion: selectedDocument.contractVersion,
          sellerReferenceText: values.sellerReference,
          buyerReferenceText: values.buyerReference,
        },
        onCompleted: () => {
          toast.success(
            `References for contract ${selectedDocument.originalFileName} saved successfully!`,
            {
              duration: 3000,
              position: 'bottom-right',
            },
          );

          setDocuments((prevDocuments) =>
            prevDocuments.map((doc) =>
              doc.contractId === selectedDocument.contractId
                ? {
                    ...doc,
                    data: {
                      ...doc.data,
                      sellerReferenceText: values.sellerReference,
                      buyerReferenceText: values.buyerReference,
                    },
                  }
                : doc,
            ),
          );
        },
        onError: (error) => {
          console.error(
            `Error saving references for contract ${selectedDocument.contractId}:`,
            error,
          );
        },
      });
    }
  };

  const handleDownload = (
    contractId: string,
    contractVersion: string,
    error?: string | null,
  ) => {
    if (error) {
      toast.error(error, {
        duration: 5000,
      });

      return;
    }

    getContractFileGetUrl({
      variables: {
        input: {
          dealId,
          contractId,
          contractVersion,
        },
      },
      onCompleted: (data) => {
        const url = data?.getContractFileGetUrl;
        if (url) {
          window.open(url, '_blank');
        } else {
          toast.error('Failed to fetch download URL');
        }
      },
      onError: (err) => {
        console.error('Error fetching contract download URL:', err);
        toast.error('Failed to fetch download URL');
      },
    });
  };

  const handleDeleteContract = (
    contractId: string,
    contractVersion: string,
  ) => {
    deleteContract({
      variables: {
        dealId,
        contractId,
        contractVersion,
      },
      onCompleted: () => {
        const updatedDocuments = documents.filter(
          (doc) => doc.contractId !== contractId,
        );
        setDocuments(updatedDocuments);
        toast.success('Contract deleted successfully!', {
          duration: 3000,
          position: 'bottom-right',
        });
      },
      onError: (error) => {
        console.error('Error deleting contract:', error);
      },
    });
  };

  const handleSign = (closeModal: () => void) => {
    const documentsToSign = documents.filter((doc) => {
      if (role === 'BUYER') {
        return !doc.data.buyerSigned;
      } else if (role === 'SELLER') {
        return !doc.data.sellerSigned;
      }
      return false;
    });

    if (isConfirmed && documentsToSign.length > 0) {
      documentsToSign.forEach((doc) => {
        saveContractSignature({
          variables: {
            dealId,
            contractId: doc.contractId,
            contractVersion: doc.contractVersion,
            consentVerbiage: 'Consent Verbiage',
            intentVerbiage: 'Intent Verbiage',
          },
          onCompleted: () => {
            toast.success(`Contract ${doc.contractId} signed successfully!`, {
              duration: 3000,
              position: 'bottom-right',
            });
            closeModal();
            setIsConfirmed(false);
          },
          onError: (error) => {
            console.error(`Error signing contract ${doc.contractId}:`, error);
          },
        });
      });
    }
  };

  const handleCheckboxChange = (
    id: string,
    contractVersion: string,
    isReady: boolean,
  ) => {
    setDocuments((prevDocuments) =>
      prevDocuments.map((doc) =>
        doc.contractId === id && doc.contractVersion === contractVersion
          ? { ...doc, data: { ...doc.data, readyToSignSeller: !isReady } }
          : doc,
      ),
    );

    markDocumentReadyToSign({
      variables: {
        dealId,
        contractId: id,
        contractVersion,
        isReady: !isReady,
      },
      refetchQueries: [
        {
          query: GET_CONTRACTS_FOR_DEAL,
          variables: { dealId },
        },
      ],
      onError: (error) => {
        console.error('Error marking document ready to sign:', error);
      },
    });
  };

  const handleSettlementComplete = () => {
    markSettlementComplete({
      variables: {
        input: { deal_id: dealId },
      },
      onCompleted: () => {
        toast.success('Settlement marked as complete!', {
          duration: 3000,
          position: 'bottom-right',
        });
      },
      onError: (error) => {
        console.error('Error marking settlement complete:', error);
        toast.error('Failed to mark settlement as complete.');
      },
    });
  };

  const areAllDocumentsReadyToSignByBoth = documents.every((doc) => {
    const { readyToSignBuyer, readyToSignSeller } = doc.data;
    return readyToSignBuyer && readyToSignSeller;
  });

  const areAllDocumentsSignedByUser = documents.every((doc) => {
    const { buyerSigned, sellerSigned } = doc.data;

    if (buyerSigned && sellerSigned) {
      return true;
    } else if (role === 'BUYER' && buyerSigned) {
      return true;
    } else if (role === 'SELLER' && sellerSigned) {
      return true;
    }

    return false;
  });

  const signModalTrigger = () => (
    <div className="mt-4 flex justify-start">
      <BaseButton label="Sign" size="medium">
        Sign all documents
      </BaseButton>
    </div>
  );

  const selectedDocument = documents.find(
    (doc) => doc.contractId === selectedDocumentId,
  );
  const areAllDocumentsSignedByBothSides =
    documents.length > 0 &&
    documents.every((doc) => doc.data.buyerSigned && doc.data.sellerSigned);

  return (
    <div>
      <p className="mb-4 text-sm uppercase text-slate-400">
        Contracts to view & sign
      </p>

      {role === 'SELLER' && !isCompleted && (
        <div>
          <p className="mb-2 font-heebo text-sm text-gray-600">
            {uploadConfig.settlementDocuments.message}
          </p>
          <FileUpload
            parentId={dealId}
            fileType={FileType.OTHER}
            allowedFileTypes={uploadConfig.settlementDocuments.allowedFileTypes}
            onFileSuccess={handleFileSuccess}
            width="auto"
            showButtons={false}
            timeToHideProgress={2}
            isContract={true}
          />
        </div>
      )}

      <div className="mt-2 w-full">
        {documents.length === 0 ? (
          <div className="mt-4 text-center text-slate-400">
            {role === 'BUYER' ? (
              <p>Waiting for the documents from the seller...</p>
            ) : (
              <p>
                No documents available. Upload the first document to start the
                process.
              </p>
            )}
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between font-medium uppercase text-foreground-muted">
              <div className="w-[40%]">DOCUMENT NAME</div>
              <div className="w-[20%] text-left">STATUS</div>
              <div className="w-[10%]"></div>
              <div className="w-[30%] text-right">Ready to be signed</div>
            </div>

            <div className="mt-2">
              {documents.map((doc) => (
                <DocumentRow
                  key={doc.contractId}
                  document={doc}
                  role={role}
                  selectedDocumentId={selectedDocumentId}
                  onRowClick={handleRowClick}
                  onDownload={handleDownload}
                  onDelete={handleDeleteContract}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {areAllDocumentsSignedByBothSides ? (
        <div className="mt-6">
          <p
            className={`${isCompleted ? 'text-gray-400' : 'text-yellow-500'} capitalize`}
          >
            {isCompleted
              ? 'Settlement is completed'
              : 'All documents are signed from both sides'}
          </p>
        </div>
      ) : (
        selectedDocumentId &&
        role === 'SELLER' && (
          <div className="mt-6">
            <p className="text-sm text-slate-400">
              Seller Reference:{' '}
              <span className="text-white">
                {selectedDocument?.data?.sellerReferenceText || 'no reference'}
              </span>
            </p>
            <p className="text-sm text-slate-400">
              Buyer Reference:{' '}
              <span className="text-white">
                {selectedDocument?.data?.buyerReferenceText || 'no reference'}
              </span>
            </p>
          </div>
        )
      )}

      {selectedDocumentId &&
        role === 'SELLER' &&
        !selectedDocument?.data?.buyerSigned &&
        !selectedDocument?.data?.sellerSigned && (
          <ContractReferencesModal
            initialValues={{
              sellerReference:
                selectedDocument?.data?.sellerReferenceText || '',
              buyerReference: selectedDocument?.data?.buyerReferenceText || '',
            }}
            onSubmit={handleSubmit}
            trigger={<DownloadLink text="Edit References" />}
          />
        )}

      {!areAllDocumentsSignedByUser && areAllDocumentsReadyToSignByBoth && (
        <ModalNotStyled trigger={signModalTrigger()}>
          {({ closeModal }) => (
            <div className="w-full max-w-[500px] rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">Intent to Sign</h2>
                <button
                  className="text-gray-500 hover:text-gray-300"
                  onClick={closeModal}
                >
                  ✕
                </button>
              </div>
              <p className="my-4">
                By clicking “Sign Now,” you are electronically signing this
                document, and your electronic signature will have the same legal
                force and effect as a handwritten signature.
              </p>
              <div className="mb-4">
                <CheckboxWithLabel
                  checked={isConfirmed}
                  onChange={() => setIsConfirmed(!isConfirmed)}
                  className="w-[22px]"
                  children={
                    <span className="ml-2 text-sm">
                      I understand that by clicking “Sign Now,” I am
                      electronically signing this document.
                    </span>
                  }
                />
              </div>
              <div className="flex justify-end gap-2">
                <BaseButton onClick={closeModal} label="Do not sign">
                  Do not sign
                </BaseButton>
                <BaseButton
                  onClick={() => handleSign(closeModal)}
                  disabled={!isConfirmed}
                  label="Sign Now"
                  className="bg-purple-600 text-white"
                >
                  Sign Now
                </BaseButton>
              </div>
            </div>
          )}
        </ModalNotStyled>
      )}

      {areAllDocumentsSignedByBothSides &&
        role === 'SELLER' &&
        !isCompleted && (
          <div className="mt-4 flex justify-start">
            <Button
              kind={KIND.primary}
              key="Finish"
              onClick={handleSettlementComplete}
            >
              Finish Settlement
            </Button>
          </div>
        )}
    </div>
  );
};

export default TransactionDocuments;
