/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Agency {
  FDIC = "FDIC",
  NCUA = "NCUA",
  NONE = "NONE",
}

export enum AssetClass {
  AIRCRAFT = "AIRCRAFT",
  AUTO = "AUTO",
  BOAT = "BOAT",
  CRE = "CRE",
  HOME = "HOME",
  MARINE = "MARINE",
  MBL = "MBL",
  MOTORCYCLE = "MOTORCYCLE",
  REVOLVING = "REVOLVING",
  RV = "RV",
  SOLAR = "SOLAR",
  UNSECPERSONAL = "UNSECPERSONAL",
  WAREHOUSE = "WAREHOUSE",
}

export enum CollateralStatus {
  ADDITIONAL_QA_REQUIRED = "ADDITIONAL_QA_REQUIRED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  RECEIVED = "RECEIVED",
  REJECTED = "REJECTED",
  REQUIRED = "REQUIRED",
}

export enum DealActionOptionType {
  APPROVE_BUYER = "APPROVE_BUYER",
  REJECT_BUYER = "REJECT_BUYER",
}

export enum DealBlocker {
  BOTH = "BOTH",
  BUYER = "BUYER",
  NEITHER = "NEITHER",
  SELLER = "SELLER",
}

export enum DealMessageAuthorRole {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

export enum DealRole {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

export enum DealStatus {
  BID_ACCEPTED = "BID_ACCEPTED",
  BID_REJECTED = "BID_REJECTED",
  BUYER_AND_SELLER_NDA = "BUYER_AND_SELLER_NDA",
  BUYER_NDA = "BUYER_NDA",
  BUYER_OFFER = "BUYER_OFFER",
  COMPLETE = "COMPLETE",
  DILIGENCE = "DILIGENCE",
  PENDING_SETTLEMENT = "PENDING_SETTLEMENT",
  REJECTED_INQUIRY = "REJECTED_INQUIRY",
  SELLER_INQUIRY = "SELLER_INQUIRY",
  SELLER_NDA = "SELLER_NDA",
  SELLER_REVIEW = "SELLER_REVIEW",
  WAIT_FOR_CUTOFF = "WAIT_FOR_CUTOFF",
  WAIT_FOR_WIRE_TRANSFER = "WAIT_FOR_WIRE_TRANSFER",
  WIRE_TRANSFER_CONFIRMED = "WIRE_TRANSFER_CONFIRMED",
  WIRE_TRANSFER_SENT = "WIRE_TRANSFER_SENT",
}

export enum DefaultAssumptionType {
  CDR = "CDR",
  SDA = "SDA",
}

export enum DiligenceBatchStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum DiligenceStatus {
  DESELECTED = "DESELECTED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_DOCUMENTS = "PENDING_DOCUMENTS",
  REJECTED = "REJECTED",
  REVIEWED = "REVIEWED",
}

export enum ExecutionType {
  ALL_OR_NOTHING = "ALL_OR_NOTHING",
  BUYER_CHOICE = "BUYER_CHOICE",
}

export enum FileState {
  DELETED = "DELETED",
  DELETE_ERROR = "DELETE_ERROR",
  DELETING = "DELETING",
  ERROR = "ERROR",
  ERROR_HEADER = "ERROR_HEADER",
  ERROR_PASSWORD = "ERROR_PASSWORD",
  NEEDS_MAPPING = "NEEDS_MAPPING",
  NEW = "NEW",
  PERSISTED = "PERSISTED",
  PERSISTING = "PERSISTING",
  PROCESSED = "PROCESSED",
  PROCESSING = "PROCESSING",
  READING_FILE_HEADERS = "READING_FILE_HEADERS",
  READY_TO_PERSIST = "READY_TO_PERSIST",
  READY_TO_PROCESS = "READY_TO_PROCESS",
  TO_DELETE = "TO_DELETE",
}

export enum FileType {
  COLLATERAL_FILE = "COLLATERAL_FILE",
  DEAL_FILE = "DEAL_FILE",
  DQ_AND_CHARGE_OFF = "DQ_AND_CHARGE_OFF",
  LENDING_POLICY = "LENDING_POLICY",
  LISTING_FILE = "LISTING_FILE",
  LOAN_TAPE = "LOAN_TAPE",
  OTHER = "OTHER",
  SAMPLE_COLLATERAL = "SAMPLE_COLLATERAL",
  TERMS_DOCUMENT = "TERMS_DOCUMENT",
  TRANSACTION_DOCUMENT = "TRANSACTION_DOCUMENT",
}

export enum FilterOperator {
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUALS = "GREATER_THAN_OR_EQUALS",
  IS = "IS",
  IS_NOT = "IS_NOT",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUALS = "LESS_THAN_OR_EQUALS",
}

export enum FilterableField {
  account_id = "account_id",
  age_months = "age_months",
  amortization_term_months = "amortization_term_months",
  arm_index = "arm_index",
  arm_index_rate = "arm_index_rate",
  arm_margin = "arm_margin",
  asset_class = "asset_class",
  auto_original_value_cents = "auto_original_value_cents",
  balloon_term_months = "balloon_term_months",
  borrower_credit_score = "borrower_credit_score",
  city = "city",
  cra = "cra",
  current_balance_cents = "current_balance_cents",
  dti = "dti",
  first_payment_date_seconds = "first_payment_date_seconds",
  first_rate_change_date_seconds = "first_rate_change_date_seconds",
  interest_only = "interest_only",
  interest_rate = "interest_rate",
  io_period_months = "io_period_months",
  irap_months = "irap_months",
  last_updated_date_seconds = "last_updated_date_seconds",
  lifetime_cap_rate = "lifetime_cap_rate",
  lifetime_floor_rate = "lifetime_floor_rate",
  listing_id = "listing_id",
  loan_terms_months = "loan_terms_months",
  ltv = "ltv",
  maturity_date_seconds = "maturity_date_seconds",
  next_payment_date_seconds = "next_payment_date_seconds",
  occupancy = "occupancy",
  original_balance_cents = "original_balance_cents",
  origination_date_seconds = "origination_date_seconds",
  periodic_cap_rate = "periodic_cap_rate",
  postal_code = "postal_code",
  product = "product",
  purpose = "purpose",
  rap_months = "rap_months",
  remaining_loan_terms_months = "remaining_loan_terms_months",
  state = "state",
  status = "status",
}

export enum ListingField {
  asset_class = "asset_class",
  company_id = "company_id",
  created_date_seconds = "created_date_seconds",
  execution_type = "execution_type",
  has_cra = "has_cra",
  loan_count = "loan_count",
  name = "name",
  origination_type = "origination_type",
  participation_rate = "participation_rate",
  pricing_rate = "pricing_rate",
  pricing_type = "pricing_type",
  provided_name = "provided_name",
  servicing_preference = "servicing_preference",
  t_current_balance_cents = "t_current_balance_cents",
  wa_borrower_credit_score = "wa_borrower_credit_score",
  wa_coupon = "wa_coupon",
  wa_current_balance_cents = "wa_current_balance_cents",
  wa_dti = "wa_dti",
  wa_loan_age_months = "wa_loan_age_months",
  wa_ltv = "wa_ltv",
  wa_remaining_loan_terms_months = "wa_remaining_loan_terms_months",
}

export enum ListingState {
  ACTIVE = "ACTIVE",
  DELETING = "DELETING",
  NEEDS_REFRESH = "NEEDS_REFRESH",
  NEW = "NEW",
  READY_TO_CREATE = "READY_TO_CREATE",
  REFRESHED = "REFRESHED",
  SCANNING = "SCANNING",
  TO_DELETE = "TO_DELETE",
}

export enum LoanField {
  account_id = "account_id",
  age_months = "age_months",
  amortization_term_months = "amortization_term_months",
  arm_index = "arm_index",
  arm_index_rate = "arm_index_rate",
  arm_margin = "arm_margin",
  asset_class = "asset_class",
  auto_original_value_cents = "auto_original_value_cents",
  auto_vin = "auto_vin",
  balloon_term_months = "balloon_term_months",
  borrower_credit_score = "borrower_credit_score",
  city = "city",
  cra = "cra",
  current_balance_cents = "current_balance_cents",
  dti = "dti",
  first_payment_date_seconds = "first_payment_date_seconds",
  first_rate_change_date_seconds = "first_rate_change_date_seconds",
  interest_only = "interest_only",
  interest_rate = "interest_rate",
  io_period_months = "io_period_months",
  irap_months = "irap_months",
  last_updated_date_seconds = "last_updated_date_seconds",
  lifetime_cap_rate = "lifetime_cap_rate",
  lifetime_floor_rate = "lifetime_floor_rate",
  loan_terms_months = "loan_terms_months",
  ltv = "ltv",
  maturity_date_seconds = "maturity_date_seconds",
  next_payment_date_seconds = "next_payment_date_seconds",
  occupancy = "occupancy",
  original_balance_cents = "original_balance_cents",
  origination_date_seconds = "origination_date_seconds",
  periodic_cap_rate = "periodic_cap_rate",
  postal_code = "postal_code",
  product = "product",
  property_description = "property_description",
  property_year = "property_year",
  purpose = "purpose",
  rap_months = "rap_months",
  remaining_loan_terms_months = "remaining_loan_terms_months",
  servicing_rate = "servicing_rate",
  state = "state",
}

export enum NewsArticleField {
  category = "category",
  id = "id",
  publication_date_seconds = "publication_date_seconds",
  sentiment_label = "sentiment_label",
  sentiment_score = "sentiment_score",
  source = "source",
  subject = "subject",
  summary = "summary",
  url = "url",
}

export enum NewsSource {
  ABA = "ABA",
  CUNA = "CUNA",
  FDIC = "FDIC",
  FEDERAL_RESERVE = "FEDERAL_RESERVE",
  NCUA = "NCUA",
  OTHER = "OTHER",
  US_TREASURY = "US_TREASURY",
}

export enum NoteStatus {
  NEW = "NEW",
  NONE = "NONE",
  READ = "READ",
}

export enum OriginationType {
  DIRECT = "DIRECT",
  INDIRECT = "INDIRECT",
  INDIRECT_FINTECH = "INDIRECT_FINTECH",
  MIXED = "MIXED",
  N_A = "N_A",
}

export enum ParentType {
  COMPANY = "COMPANY",
  DEAL = "DEAL",
  LISTING = "LISTING",
}

export enum PerformanceClass {
  NON_PERFORMING = "NON_PERFORMING",
  PERFORMING = "PERFORMING",
  RE_PERFORMING = "RE_PERFORMING",
  SUB_PERFORMING = "SUB_PERFORMING",
}

export enum PrepaymentAssumptionType {
  CPR = "CPR",
  PSA = "PSA",
}

export enum PricingType {
  NONE = "NONE",
  PUBLISHED = "PUBLISHED",
  RESERVED = "RESERVED",
}

export enum ProductType {
  ARM = "ARM",
  FIXED = "FIXED",
}

export enum RateType {
  RiskFree = "RiskFree",
  Sofr = "Sofr",
}

export enum ServicingPreference {
  NONE = "NONE",
  RELEASED = "RELEASED",
  RETAINED = "RETAINED",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export enum SortableField {
  account_id = "account_id",
  age_months = "age_months",
  asset_class = "asset_class",
  auto_original_value_cents = "auto_original_value_cents",
  borrower_credit_score = "borrower_credit_score",
  city = "city",
  current_balance_cents = "current_balance_cents",
  dti = "dti",
  first_payment_date_seconds = "first_payment_date_seconds",
  first_rate_change_date_seconds = "first_rate_change_date_seconds",
  interest_rate = "interest_rate",
  last_updated_date_seconds = "last_updated_date_seconds",
  loan_terms_months = "loan_terms_months",
  ltv = "ltv",
  maturity_date_seconds = "maturity_date_seconds",
  next_payment_date_seconds = "next_payment_date_seconds",
  original_balance_cents = "original_balance_cents",
  origination_date_seconds = "origination_date_seconds",
  postal_code = "postal_code",
  product = "product",
  remaining_loan_terms_months = "remaining_loan_terms_months",
  servicing_rate = "servicing_rate",
  state = "state",
}

/**
 * Types of stipulations a buyer can save to their carve
 */
export enum StipulationFieldName {
  bid_basis_points = "bid_basis_points",
  diligence_completion_date_seconds = "diligence_completion_date_seconds",
  min_net_yield_to_buyer = "min_net_yield_to_buyer",
  percent_collateral_diligence = "percent_collateral_diligence",
  servicing_rate = "servicing_rate",
  settlement_date_seconds = "settlement_date_seconds",
}

export enum StipulationToggleName {
  has_diligence_completion_date_seconds = "has_diligence_completion_date_seconds",
  has_min_net_yield_to_buyer = "has_min_net_yield_to_buyer",
  has_percent_collateral_diligence = "has_percent_collateral_diligence",
  has_settlement_date_seconds = "has_settlement_date_seconds",
  is_servicing_retained = "is_servicing_retained",
}

export enum TimelineStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum Uploader {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

/**
 * Add the UserScope and UserEntity type definitions
 */
export enum UserEntity {
  company = "company",
  listing = "listing",
}

export enum UserRole {
  admin = "admin",
  deal_user = "deal_user",
  research_user = "research_user",
  user = "user",
  viewer = "viewer",
}

export interface AcceptBidInput {
  deal_id: string;
}

export interface AddCollateralNoteInput {
  dealID: string;
  loanID: string;
  fileName: string;
  note: string;
  authorRole: DealMessageAuthorRole;
}

export interface AutoMapFieldsInput {
  columns: string[];
  sampleRows?: (string[] | null)[] | null;
  enums: string[];
  fileId?: string | null;
}

export interface CalculateBidPriceInput {
  deal_id: string;
  bid_basis_points?: number | null;
}

export interface CollateralFileDownloadURLInput {
  dealID: string;
  loanID?: string | null;
  fileName: string;
}

export interface CollateralFileUploadURLInput {
  dealID: string;
  loanID?: string | null;
  fileName: string;
}

export interface ConfirmWireTransferInput {
  deal_id: string;
}

export interface CreateDealCarveInput {
  deal_id: string;
  name: string;
  carve: Filter[];
}

export interface CreateDealMessageInput {
  deal_id: string;
  event_id?: string | null;
  message: string;
}

export interface CreateDiligenceNoteInput {
  diligence_id: string;
  note: string;
}

export interface DealMessageLastEvaluatedKeyInput {
  id: string;
  deal_id: string;
  created_time: string;
}

export interface DealMessagePaginationInput {
  sort_direction?: SortDirection | null;
  limit?: number | null;
  last_evaluated_key?: DealMessageLastEvaluatedKeyInput | null;
}

export interface DeleteDealCarveInput {
  id: string;
}

/**
 * Input types for the new Collateral File and Diligence AI handling.
 */
export interface DiligenceInput {
  dealId: string;
}

/**
 * Filter model that requires field_name and operator to be set.  Operand is the default, and only operand OR operandList can be set.  Not both!
 */
export interface Filter {
  field_name: FilterableField;
  operator: FilterOperator;
  operand?: string | null;
  operandList?: string[] | null;
}

export interface GetContractFileGetUrlInput {
  dealId: string;
  contractId: string;
  contractVersion: string;
}

export interface GetContractFilePostUrlInput {
  dealId: string;
  fileName: string;
  contentType?: string | null;
  contractId?: string | null;
}

export interface GetFileUuidInput {
  parentId?: string | null;
  fileType: FileType;
  fileName: string;
  s3VersionId: string;
  assetClass?: AssetClass | null;
  productType?: ProductType | null;
}

export interface GetSelectDiligenceStatusInput {
  deal_id: string;
  id: string;
}

export interface GetValidTermsInput {
  parentType: ParentType;
  parentId?: string | null;
  fieldNames: FilterableField[];
  filters?: Filter[] | null;
}

export interface HeaderMapInput {
  file_column: string;
  field: LoanField;
}

/**
 * Listing Filter model that requires field_name and operator to be set.  Operand is the default, and only operand OR operandList can be set.  Not both!
 */
export interface ListingFilter {
  field_name: ListingField;
  operator: FilterOperator;
  operand?: string | null;
  operandList?: string[] | null;
}

export interface MarkCollateralReviewCompleteInput {
  deal_id: string;
}

export interface MarkDiligenceAcceptedInput {
  deal_id: string;
  accepted: boolean;
}

export interface MarkDiligenceCompleteInput {
  deal_id: string;
}

export interface MarkReadRequest {
  notificationID: string;
}

export interface MarkSettlementCompleteInput {
  deal_id: string;
}

export interface MultipleCollateralFilesMetadataInput {
  dealID: string;
}

export interface NewsFilter {
  field_name: NewsArticleField;
  operator: FilterOperator;
  operand?: string | null;
  operandList?: string[] | null;
}

export interface NewsSort {
  sort_field?: NewsArticleField | null;
  sort_direction?: SortDirection | null;
}

/**
 * The pagination inputs.  Instead of an offset, we use a cursor to indicate where we want the page of results to start.
 */
export interface Pagination {
  page_size?: number | null;
  cursor_id?: string | null;
  cursor_sorted_value?: string | null;
  offset?: number | null;
}

export interface RejectBidInput {
  deal_id: string;
}

export interface RemoveCollateralFileRequest {
  dealID: string;
  loanID: string;
  fileName: string;
}

export interface SaveSellerBidInput {
  deal_id: string;
  seller_bid_basis_points: number;
}

export interface SaveWireDetailsInput {
  deal_id: string;
  account_number: string;
  bank_name: string;
  routing_number: string;
}

export interface SelectDiligenceLoansInput {
  deal_id: string;
  loan_ids: string[];
}

export interface SentWireTransferInput {
  deal_id: string;
}

export interface SingleCollateralFileMetadataInput {
  dealID: string;
  loanID: string;
  fileName: string;
}

export interface Sort {
  sort_field?: SortableField | null;
  sort_direction?: SortDirection | null;
}

export interface SubmitBidInput {
  deal_id: string;
  bid_basis_points: number;
  carve_id: string;
  document_ids?: string[] | null;
}

export interface SubmitReferralInput {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  company: string;
}

export interface UpdateCollateralStatusInput {
  dealID: string;
  loanID: string;
  fileName: string;
  newStatus: CollateralStatus;
}

export interface UpdateDealCarveInput {
  id: string;
  name?: string | null;
  carve?: Filter[] | null;
}

export interface UserManagementAddUserScopeRequest {
  userEmail?: string | null;
  userID?: string | null;
  companyID: string;
  scopes: UserScopeInput[];
}

export interface UserManagementCreateUserRequest {
  userEmail: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
  companyID: string;
  roles: UserRole[];
}

export interface UserManagementGetUserRequest {
  userEmail?: string | null;
  userID?: string | null;
}

export interface UserManagementListCompanyPermissionsRequest {
  companyID: string;
}

export interface UserManagementListCompanyUserPermissionsRequest {
  companyID: string;
}

export interface UserManagementRemoveUserFromCompanyRequest {
  userEmail?: string | null;
  userID?: string | null;
  companyID: string;
}

export interface UserManagementUpdateUserInfoRequest {
  userEmail: string;
  givenName: string;
  familyName: string;
}

export interface UserManagementUpdateUserRolesRequest {
  userEmail?: string | null;
  userID?: string | null;
  companyID: string;
  roles: UserRole[];
}

export interface UserManagementUpdateUserScopesRequest {
  userEmail?: string | null;
  userID?: string | null;
  companyID: string;
  scopes: UserScopeInput[];
}

export interface UserScopeInput {
  entity: UserEntity;
  id: string;
  role: UserRole;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
