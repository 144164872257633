import React, { useState, useRef, useEffect } from 'react';

import { usePdf } from '@mikecousins/react-pdf';
import {
  IconZoomIn,
  IconZoomOut,
  IconArrowLeft,
  IconArrowRight,
  IconDownload,
} from '@tabler/icons-react';
import { PDFDocumentProxy } from 'pdfjs-dist';

export interface DocumentPreviewProps {
  documentUrl: string;
  fileExtension?: string;
}

const DocumentPreview = ({
  documentUrl,
  fileExtension,
}: DocumentPreviewProps) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [scale, setScale] = useState(1.0);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const onDocumentComplete = (pdf: PDFDocumentProxy) => {
    setPages(pdf.numPages);
  };

  usePdf({
    file: documentUrl,
    page,
    canvasRef,
    scale,
    onDocumentLoadSuccess: onDocumentComplete,
  });

  const handleZoomIn = () => {
    console.log('zooming in');
    setScale((prev) => Math.min(prev + 0.25, 3));
  };
  const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.25, 0.5));

  useEffect(() => {
    canvasRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  const renderPdfPreview = () => (
    <div className="flex h-screen flex-col bg-gray-200">
      <div className="flex items-center justify-between bg-gray-800 px-4 py-2 text-white">
        <div className="flex gap-4">
          <button
            onClick={handleZoomOut}
            disabled={scale <= 0.5}
            className="flex items-center rounded hover:text-blue-400 disabled:text-gray-500"
          >
            <IconZoomOut size={20} />
          </button>

          <button
            onClick={handleZoomIn}
            disabled={scale >= 3}
            className="flex items-center rounded hover:text-blue-400 disabled:text-gray-500"
          >
            <IconZoomIn size={20} />
          </button>

          <button
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
            disabled={page <= 1}
            className="flex items-center rounded hover:text-blue-400 disabled:text-gray-500"
          >
            <IconArrowLeft size={20} />
          </button>

          <button
            onClick={() => setPage((prev) => Math.min(prev + 1, pages))}
            disabled={page >= pages}
            className="flex items-center rounded hover:text-blue-400 disabled:text-gray-500"
          >
            <IconArrowRight size={20} />
          </button>
        </div>

        <div className="text-sm">
          Page {page} of {pages}
        </div>

        <a
          href={documentUrl}
          download
          className="flex items-center rounded hover:text-blue-400"
        >
          <IconDownload size={20} />
        </a>
      </div>
      <div className="overflow-y-auto">
        <canvas ref={canvasRef} />
      </div>
    </div>
  );

  const renderImagePreview = () => (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <img
        src={documentUrl}
        alt="Document Preview"
        className="max-h-full max-w-full"
      />
    </div>
  );

  const renderDownloadButton = () => (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <a
        href={documentUrl}
        download
        className="text-blue-500 underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download Document
      </a>
    </div>
  );

  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'webp':
      return renderImagePreview();

    case 'pdf':
      return renderPdfPreview();

    default:
      return renderDownloadButton();
  }
};

export default DocumentPreview;
