import { useEffect, useMemo, useState, useCallback, useRef } from 'react';

import { uploadConfig } from 'app-level/config/uploadConfig';
import { Spinner } from 'baseui/spinner';
import { useDialog } from 'common-ui/Dialog';
import { FileDropZone } from 'features/common-elements/FileDropZone';
import { useS3Uploader } from 'features/common-elements/useS3Uploader';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import { DateTime } from 'luxon';
import { FileUploadProgressBar } from 'ui-kit';

import { useLazyQuery } from '@apollo/client';

import {
  CollateralStatus,
  DealRole,
  DiligenceStatus,
  FileType,
} from '__generated__/globalTypes';

import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';
import { GET_COLLATERAL_FILE_DOWNLOAD_URL } from 'query/collateral';

import { Action } from './ActionButton';
import CollateralNotesDialog from './CollateralNotesDialog';
import { Note } from './DealDocumentTypes';
import DocumentPreview from './DocumentPreview';
import { DocumentPreviewSkeleton } from './DocumentPreviewSkeleton';
import DocumentsTable from './DocumentsTable';
import { FileDiligenceStatus } from './FileStatus';
import { useCollateralNotesForDeal } from './useCollateralNotes';
import ConfirmDialog from '../../../../common-ui/ConfirmDialog';

const getFileExtension = (url: string): string => {
  const fileName = url.split('?')[0].split('/').pop();
  return fileName?.split('.').pop()?.toLowerCase() || '';
};

export interface DiligenceSelectorProps {
  selectedLoanId: string;
  onSelectLoan?: (loanId: string) => void;
  diligence: GetDealDiligence_deal_diligence[];
}

export interface DocumentsProps {
  collateralNotesMap: Record<string, Note[]>;
  onFileStatusSelect: (
    dealId: string,
    loanId: string,
    fileName: string,
    status: FileDiligenceStatus,
  ) => void;
  onRemoveFile: (docId: string, loanId: string, fileName: string) => void;
  uploadProgress?: number;
  onSuccessUpload: () => void;
}

export interface DiligenceLoanDocumentDrawerProps {
  dealName: string;
  role: DealRole;
  diligenceSelector: DiligenceSelectorProps;
  documents: DocumentsProps;
  userId: string;
  dealId: string;
}

const DiligenceLoanDocumentDrawer = ({
  dealName,
  role,
  diligenceSelector: { selectedLoanId, onSelectLoan, diligence },
  documents: {
    collateralNotesMap,
    uploadProgress,
    onFileStatusSelect,
    onRemoveFile,
    onSuccessUpload,
  },
  userId,
  dealId,
}: DiligenceLoanDocumentDrawerProps) => {
  const [selectedDocId, setSelectedDocId] = useState<string>();
  const [docUrl, setDocUrl] = useState<string | undefined>();
  const [fileExtension, setFileExtension] = useState<string>();
  const [progress, setProgress] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const { setDocumentSelectedForView } = useDealRoomContext();

  const [fetchDocumentUrl] = useLazyQuery(GET_COLLATERAL_FILE_DOWNLOAD_URL, {
    onCompleted: (data) => {
      const url = data.getCollateralFileDownloadURL.URL;
      setDocUrl(url);
      setFileExtension(getFileExtension(url));
    },
    onError: (error) => console.error('Error fetching document URL:', error),
  });

  const selectedDiligence = useMemo(
    () => diligence.find(({ loan: { id } }) => id === selectedLoanId),
    [selectedLoanId, diligence],
  );

  const { upload } = useS3Uploader({
    onSuccess: (_file, versionId) => {
      console.log(`File uploaded successfully. Version ID: ${versionId}`);
      setIsUploading(false);
      setProgress(null);
      onSuccessUpload();
    },
    onProgress: (progress) => {
      console.log(`File upload progress: ${progress}%`);
      setProgress(progress);
    },
    onError: (error: unknown) => {
      console.error('Error uploading file:', error);
      setIsUploading(false);
      setProgress(null);
      setUploadedFileName(null);
    },
    fileType: FileType.COLLATERAL_FILE,
    parentId: dealId,
    isCollateral: true,
    loanId: selectedDiligence?.loan?.id,
    uploadedBy: userId,
  });

  const handleDocumentSelection = useCallback(() => {
    if (selectedDocId) {
      setDocumentSelectedForView(true); // Set to true when a document IN DOCUMENTS PREVIW  is selected
    } else {
      setDocumentSelectedForView(false); // Set to false if no document IN DOCUMENTS PREVIW  is selected
    }

    return () => {
      setDocumentSelectedForView(false); // Reset to false when component is unmounted
    };
  }, [selectedDocId, setDocumentSelectedForView]);

  // Call the handleDocumentSelection callback when `selectedDocId` changes
  useEffect(() => {
    handleDocumentSelection();
  }, [handleDocumentSelection]);

  // Automatically select the first document when the list of documents changes.
  useEffect(() => {
    if (
      !selectedDiligence?.collateral.some(
        (doc) => doc.collateralID === selectedDocId && doc.loanID,
      )
    ) {
      setSelectedDocId(
        selectedDiligence?.collateral?.find((doc) => doc.loanID)
          ?.collateralID || undefined,
      );
    }
  }, [selectedLoanId, selectedDocId, selectedDiligence]);

  const [currentNotesDocumentId, setCurrentNotesDocumentId] =
    useState<string>();

  const currentNotesDocument = selectedDiligence?.collateral.find(
    (doc) => doc.collateralID === currentNotesDocumentId,
  );

  const noteDialog = useDialog();

  const handleNoteClick = (docId: string) => {
    setCurrentNotesDocumentId(docId);
    noteDialog.openDialog();
  };

  const confirmRemoveFileDialog = useDialog();

  const handleClickRemove = () => {
    confirmRemoveFileDialog.openDialog();
  };

  const selectedDoc = selectedDiligence?.collateral.find(
    (doc) => doc.collateralID === selectedDocId,
  );
  // Refetch url after 14min if it is not used
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchUrl = async () => {
      if (selectedDocId && selectedDiligence) {
        const selectedDoc = selectedDiligence.collateral.find(
          (doc) => doc.collateralID === selectedDocId,
        );

        if (selectedDoc) {
          try {
            const result = await fetchDocumentUrl({
              variables: {
                input: {
                  dealID: selectedDoc.dealID,
                  loanID: selectedDoc.loanID,
                  fileName: selectedDoc.fileName,
                },
              },
            });

            if (result?.data?.getCollateralFileDownloadURL?.URL) {
              setDocUrl(result.data.getCollateralFileDownloadURL.URL);
            } else {
              setDocUrl(undefined);
            }
          } catch (error) {
            console.error('Error fetching document URL:', error);
            setDocUrl(undefined);
          }
        }
      } else {
        setDocUrl(undefined);
      }
    };

    fetchUrl();

    timerRef.current = setInterval(
      () => {
        console.log('Interval triggered');
        fetchUrl();
      },
      5 * 60 * 1000,
    );

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [selectedDocId, selectedDiligence, fetchDocumentUrl]);

  const documentPreview = useMemo(() => {
    if (!selectedDiligence?.collateral?.length) return null;
    if (!docUrl) return <DocumentPreviewSkeleton />;
    return (
      <DocumentPreview documentUrl={docUrl} fileExtension={fileExtension} />
    );
  }, [docUrl, fileExtension, selectedDiligence?.collateral]);

  const { notesMap } = useCollateralNotesForDeal(dealId);

  const hasNote = (collateralId: string) => notesMap[collateralId] || false;

  const onFileDrop = async (file: File) => {
    try {
      setIsUploading(true);
      setUploadedFileName(file.name);
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsUploading(false);
      setProgress(null);
      setUploadedFileName(null);
    }
  };

  const handleLoanSelection = (loanId: string) => {
    const selectedLoan = diligence.find(({ loan: { id } }) => id === loanId);

    if (onSelectLoan) {
      onSelectLoan(loanId);
    }

    if (!selectedLoan?.collateral.length) {
      setSelectedDocId(undefined);
      setDocUrl(undefined);
    }
  };

  return (
    <>
      <div className="flex h-full flex-col gap-2 bg-slate-900 p-4 text-white">
        <div className="mt-4 flex flex-nowrap gap-x-3">
          <div className="w-64 rounded-md border border-gray-900 p-4">
            <div className="mb-4 flex justify-between">
              <span className="font-medium text-gray-50">{dealName}</span>
              <span className="font-bold text-gray-200">
                {diligence.length}
              </span>
            </div>
            <div>
              {diligence.map(
                ({ loan: { id: loanId, account_id: accountId } }) => (
                  <div
                    key={loanId}
                    aria-selected={loanId === selectedLoanId ? 'true' : 'false'}
                    className={`cursor-pointer p-2 ${
                      loanId === selectedLoanId
                        ? 'bg-purple-500 text-white'
                        : 'bg-transparent'
                    }`}
                    onClick={() => handleLoanSelection(loanId)}
                  >
                    {accountId}
                  </div>
                ),
              )}
            </div>
          </div>

          <div className="flex w-[calc(100%-278px)] flex-col gap-2">
            {role === DealRole.SELLER && (
              <div className="relative">
                <FileDropZone
                  onFileDrop={onFileDrop}
                  allowedFileTypes={
                    uploadConfig.collateralDocuments.allowedFileTypes
                  }
                  allowedTypesMessage={uploadConfig.collateralDocuments.message}
                  maxFiles={1}
                />
                {isUploading && (
                  <div className="absolute top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 text-sm text-blue-500">
                    <Spinner size={34} />
                  </div>
                )}
                {progress && uploadedFileName && (
                  <div className="my-2">
                    <FileUploadProgressBar
                      fileName={uploadedFileName}
                      progress={progress}
                      width="100%"
                    />
                  </div>
                )}
              </div>
            )}
            <DocumentsTable
              role={role}
              // check if document is ZIP, we do not show .ZIP in list
              collateral={selectedDiligence?.collateral.filter(
                (doc) => !!doc.loanID,
              )}
              selectedDocId={selectedDocId}
              onDocSelected={setSelectedDocId}
              handleNoteClick={handleNoteClick}
              onFileStatusSelect={onFileStatusSelect}
              hasNote={hasNote}
            />
            <div className="flex items-center justify-end gap-5">
              <div className="flex items-center gap-5">
                {role === DealRole.SELLER && (
                  <Action
                    label="Remove"
                    icon="close-round"
                    onClick={handleClickRemove}
                    disabled={!selectedDocId}
                  />
                )}
                <Action
                  as="a"
                  download
                  href={docUrl}
                  target="_blank"
                  label="Download"
                  icon="download-icon"
                  disabled={!selectedDocId}
                />
                <Action
                  label="Add Note"
                  icon="note-icon"
                  onClick={() => handleNoteClick(selectedDocId || '')}
                  disabled={!selectedDocId}
                />
                {role === DealRole.BUYER && (
                  <Action
                    label="Mark Complete"
                    icon="complete-icon"
                    onClick={() =>
                      onFileStatusSelect &&
                      selectedDocId &&
                      onFileStatusSelect(
                        dealId,
                        selectedDiligence?.loan?.id || '',
                        selectedDoc?.fileName || '',
                        'File Reviewed',
                      )
                    }
                    disabled={!selectedDocId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {documentPreview}
      </div>
      {currentNotesDocumentId && (
        <CollateralNotesDialog
          dialog={noteDialog}
          fileName={currentNotesDocument?.fileName || ''}
          dealID={dealId}
          loanID={selectedDiligence?.loan?.id || ''}
          role={role}
        />
      )}

      <ConfirmDialog
        dialog={confirmRemoveFileDialog}
        message={'Remove file "' + selectedDoc?.fileName + '"?'}
        confirmButtonText="Remove"
        onConfirm={() =>
          onRemoveFile &&
          selectedDocId &&
          onRemoveFile(
            selectedDocId,
            selectedDoc?.fileName || '',
            selectedDiligence?.loan?.id || '',
          )
        }
      />
    </>
  );
};

export default DiligenceLoanDocumentDrawer;

export const formatDate = (date: string): string => {
  if (!date) return '-';
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat('yyyy-MM-dd h:mma');
};
