import React from 'react';

import {
  useFloating,
  useDismiss,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';
import { BaseButton } from 'common-ui';
import { FileUploadProgressBar } from 'ui-kit';

import loadAnim from '../../assets/dna-load-anim.gif';
import successIcon from '../../assets/pngs/check-circle.png';
interface InProgressOverlayProps {
  open: boolean;
  cancel: () => void;
  onSuccessClicked: () => void;
  title: string;
  description?: string;
  subDescription?: string;
  progress: number;
  cancelButtonText?: string;
  successButtonText?: string;
  isSuccess?: boolean;
}

export const InProgressOverlay: React.FC<InProgressOverlayProps> = ({
  open,
  cancel,
  onSuccessClicked,
  title,
  description,
  subDescription,
  progress,
  cancelButtonText = 'Cancel',
  successButtonText = 'OK',
  isSuccess,
}) => {
  const { context } = useFloating({
    open,
    onOpenChange: (open) => open || cancel(),
  });

  const dismiss = useDismiss(context, { outsidePress: false });
  const { getFloatingProps } = useInteractions([dismiss]);

  if (!open) {
    return null;
  }

  const Icon = isSuccess ? (
    <div
      className="mb-4 h-[100px] w-[100px] bg-contain bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${successIcon})` }}
    />
  ) : (
    <div
      className="mb-4 h-[135px] w-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${loadAnim})` }}
    />
  );

  const buttonType = isSuccess ? 'primary' : 'secondary';
  const buttonText = isSuccess ? successButtonText : cancelButtonText;
  const onButtonClicked = isSuccess ? onSuccessClicked : cancel;

  return (
    <FloatingPortal>
      <div className="fixed inset-0 z-50 grid place-items-center bg-black bg-opacity-10">
        <FloatingFocusManager context={context}>
          <div
            {...getFloatingProps()}
            className="flex min-h-[397px] w-[422px] flex-col items-center rounded-lg border border-pink-500 bg-background-canvas px-4 py-10 shadow-lg"
          >
            <div className="flex w-full justify-center">{Icon}</div>
            <div className="text-center text-lg font-semibold text-white">
              {title}
            </div>
            {description && (
              <div className="mb-4 mt-2 text-center text-white">
                {description}
              </div>
            )}
            {subDescription && (
              <div className="mb-4 text-center text-sm text-gray-600">
                {subDescription}
              </div>
            )}
            <div className="mx-auto w-[224px]">
              {!isSuccess && (
                <FileUploadProgressBar
                  progress={progress}
                  barHeight="20px"
                  width="100%"
                />
              )}
            </div>
            <div className="mt-6 w-[222px]">
              <BaseButton
                type={buttonType}
                label="action"
                onClick={onButtonClicked}
                size="large"
                className="w-full justify-center"
              >
                {buttonText}
              </BaseButton>
            </div>
          </div>
        </FloatingFocusManager>
      </div>
    </FloatingPortal>
  );
};
