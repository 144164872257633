import { FC, useState } from 'react';

import { BaseButton } from 'common-ui/Buttons/BaseButton/BaseButton';
import { TemplateDocument } from 'features/pages/portfolio/DocumentLibrary/__generated__/TemplateDocument';
import { FILE_TYPES } from 'features/pages/portfolio/DocumentLibrary/DocumentLibrary';
import DocumentTemplates from 'features/pages/portfolio/DocumentLibrary/DocumentTemplates';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import { Dropdown, DropdownOption } from 'ui-kit';

import { useQuery } from '@apollo/client';

import { FileType } from '__generated__/globalTypes';

import { GetDocumentLibrary } from 'query/__generated__/GetDocumentLibrary';
import { GET_DOCUMENT_LIBRARY } from 'query/documentLibrary';

export const DocumentLibraryUpload: FC = () => {
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOption | null>(null);

  const navigate = useNavigate();

  const { data, refetch } = useQuery<GetDocumentLibrary>(GET_DOCUMENT_LIBRARY, {
    fetchPolicy: 'cache-and-network',
  });

  const handleCategoryChange = (selectedOption: DropdownOption) => {
    setSelectedCategory(selectedOption);
  };

  const categoryOptions = FILE_TYPES.map((fileType) => ({
    value: fileType.type,
    label: fileType.name,
  }));

  const handleFileSuccess = (file?: File) => {
    toast.success(
      <div className="flex flex-col">
        <p>Upload was successful</p>
        <p>
          Your document '{file?.name}' was successfully uploaded under{' '}
          <b>
            {
              categoryOptions.find(
                (opt) => opt.value === selectedCategory?.value,
              )?.label
            }
          </b>
          .
        </p>
      </div>,
      {
        duration: 4000,
        position: 'bottom-right',
      },
    );
    refetch();
  };

  const companyId = data?.user?.company?.id || '';
  const documents = _.uniqBy(
    [
      ...(data?.user?.company?.transactionDocs || []),
      ...(data?.user?.company?.lendingDocs || []),
      ...(data?.user?.company?.dqDocs || []),
      ...(data?.user?.company?.sampleCollateralDocs || []),
      ...(data?.user?.company?.otherDocs || []),
      ...(data?.user?.company?.templates || []),
    ],
    'id',
  );

  return (
    <div>
      <div className="mb-4 flex flex-row items-center justify-between">
        <h2 className="font-heebo text-lg font-medium text-white">
          Document Library Upload
        </h2>
        <BaseButton
          label="Tape upload"
          size="small"
          type="secondary"
          className="h-fit w-fit"
          onClick={() => {
            navigate('/portfolio/document-library');
          }}
        >
          View Document Library
        </BaseButton>
      </div>

      <div className="mb-4">
        <Dropdown
          options={categoryOptions}
          onValueChange={handleCategoryChange}
          placeholder="Select Category"
          value={selectedCategory?.label}
        />
      </div>
      <FileUpload
        parentId={companyId}
        fileType={selectedCategory?.value as FileType}
        allowedFileTypes={[]}
        disabled={!selectedCategory}
        onFileSuccess={handleFileSuccess}
        width="auto"
        showButtons={false}
        timeToHideProgress={2}
      />
      <DocumentTemplates
        docs={(documents as TemplateDocument[]) || []}
        label="Recent Documents"
        showDelete={true}
        dealId={companyId}
        refetchQueries={[{ query: GET_DOCUMENT_LIBRARY }]}
        showInRow={true}
        onHomePage={true}
      />
    </div>
  );
};

export default DocumentLibraryUpload;
