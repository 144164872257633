import React from 'react';

import styles from './AnimationsEllipsis.module.scss';

interface AnimationsEllipsisProps {
  fontSize?: string;
  className?: string;
}

export const AnimationsEllipsis: React.FC<AnimationsEllipsisProps> = ({
  fontSize = '1em',
  className,
}) => {
  return (
    <p className="relative w-[14px]">
      <span
        className={`${styles.ellipsis} ${className} absolute bottom-[1px]`}
        style={{ fontSize }}
      ></span>
    </p>
  );
};
