import React from 'react';

import {
  IconFileTypeXls,
  IconFileTypePdf,
  IconPhotoDown,
  IconFileTypeCsv,
  IconFileTypeTxt,
} from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

interface DownloadDocumentButtonProps {
  downloadUrl?: string;
  onClick?: () => void;
  fileType: 'excel' | 'pdf' | 'image' | 'csv' | 'txt';
  tooltipText?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}

export const DownloadDocumentButton: React.FC<DownloadDocumentButtonProps> = ({
  downloadUrl,
  onClick,
  fileType,
  tooltipText = 'Download file',
  tooltipPosition = 'left',
  className,
}) => {
  const renderIcon = () => {
    switch (fileType) {
      case 'excel':
        return <IconFileTypeXls stroke={2} />;
      case 'pdf':
        return <IconFileTypePdf stroke={2} />;
      case 'image':
        return <IconPhotoDown stroke={2} />;
      case 'csv':
        return <IconFileTypeCsv stroke={2} />;
      case 'txt':
        return <IconFileTypeTxt stroke={2} />;
      default:
        return null;
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <a
      href={downloadUrl || '#'}
      target="_blank"
      rel="noopener noreferrer"
      data-tooltip-id="download"
      className={`bg-transparent flex items-center justify-center rounded-md border border-transparent-0 p-2 text-white transition-all duration-300 hover:border-pink-500 hover:bg-gray-900 ${className}`}
      onClick={handleClick}
    >
      {renderIcon()}
      {tooltipText && (
        <Tooltip
          id="download"
          className="max-w-[300px]"
          place={tooltipPosition}
        >
          {tooltipText}
        </Tooltip>
      )}
    </a>
  );
};
