import { useMemo } from 'react';

import { CounterpartyDetails } from 'features/deals/dealCards/CounterpartyCard';
import DealButton from 'ui-kit/DealButton/DealButton';
import { ProgressBar } from 'ui-kit';

import { useMutation } from '@apollo/client';

import { DealActionOptionType, DealRole } from '__generated__/globalTypes';

import {
  AdvanceDeal,
  AdvanceDealVariables,
} from 'mutation/__generated__/AdvanceDeal';
import { ADVANCE_DEAL } from 'mutation/advanceDeal';

import { CounterpartyCard_CounterpartyCard } from './__generated__/CounterpartyCard';
import { GetTimelineVariables } from '../../__generated__/GetTimeline';
import { GET_DEAL_TIMELINE } from '../../fragments';

type Props = {
  dealId: string;
  role: string;
} & CounterpartyCard_CounterpartyCard;

const CounterpartyCardBody = (props: Props): JSX.Element => {
  const { dealId, counterparty, is_under_review, role } = props;
  const isSeller = role === DealRole.SELLER;

  const [advanceDeal] = useMutation<AdvanceDeal, AdvanceDealVariables>(
    ADVANCE_DEAL,
  );

  const getTimelineVariables: GetTimelineVariables = useMemo(
    () => ({
      id: dealId,
    }),
    [dealId],
  );

  const buttons = is_under_review && isSeller && (
    <>
      <DealButton
        variant="success"
        label="Approve Buyer Access"
        onClick={() =>
          advanceDeal({
            variables: {
              id: dealId,
              action: DealActionOptionType.APPROVE_BUYER,
            },
            refetchQueries: [
              {
                query: GET_DEAL_TIMELINE,
                variables: getTimelineVariables,
              },
            ],
          })
        }
      >
        Approve Buyer Access
      </DealButton>
      <DealButton
        variant="reject"
        label="Deny Access Request"
        onClick={() =>
          advanceDeal({
            variables: {
              id: dealId,
              action: DealActionOptionType.REJECT_BUYER,
            },
            refetchQueries: [
              {
                query: GET_DEAL_TIMELINE,
                variables: getTimelineVariables,
              },
            ],
          })
        }
      >
        Deny Access Request
      </DealButton>
    </>
  );

  const metrics = useMemo(
    () => ({
      'Loans/Shares': counterparty?.regulatory_metrics?.loans_shares_rank,
      Delinquency: counterparty?.regulatory_metrics?.delinquency_rank,
      'Charge Offs': counterparty?.regulatory_metrics?.charge_off_ratio_rank,
      'Net Worth Ratio': counterparty?.regulatory_metrics?.net_worth_ratio_rank,
      'Expense Ratio': counterparty?.regulatory_metrics?.expense_ratio_rank,
      ROA: counterparty?.regulatory_metrics?.roa_rank,
      ROE: counterparty?.regulatory_metrics?.roe_rank,
      'Allowance/Total Loans':
        counterparty?.regulatory_metrics?.allowance_total_loans_rank,
      'Efficiency Ratio':
        counterparty?.regulatory_metrics?.efficiency_ratio_rank,
      'IEA/Total Assets':
        counterparty?.regulatory_metrics?.iea_total_assets_rank,
    }),
    [counterparty],
  );

  const averageIndex = useMemo(() => {
    const ranks = Object.values(metrics).filter(
      (rank): rank is number => rank != null,
    );
    return ranks.length > 0
      ? ranks.reduce((acc, rank) => acc + rank, 0) / ranks.length
      : 0;
  }, [metrics]);

  return (
    <>
      <div className="flex gap-4">
        {metrics && (
          <div className="w-[400px]">
            <div>
              <div className="mb-2 flex justify-between border-b border-t border-slate-500 pb-[9px] pt-[7px]">
                <div className="flex">
                  <p className="text-sm uppercase text-slate-200">
                    Regulatory Index
                  </p>
                </div>
                <p className="text-sm text-slate-200">
                  {averageIndex.toFixed(2)}th Index
                </p>
              </div>
              {Object.entries(metrics).map(([label, rank]) => (
                <ProgressBar key={label} label={label} rank={rank} />
              ))}
            </div>
          </div>
        )}
        <CounterpartyDetails {...props} />
      </div>
      <div className="mt-2.5 flex flex-row gap-2.5">{buttons}</div>
    </>
  );
};

export default CounterpartyCardBody;
