import { IconCloudUpload } from '@tabler/icons-react';
import { BaseButton } from 'common-ui';
import Dropzone, { FileRejection } from 'react-dropzone';
import toast from 'react-hot-toast';

export interface FileDropZoneProps {
  onFileDrop?: (file: File) => void;
  allowedFileTypes?: string[];
  width?: string;
  height?: string;
  disabled?: boolean;
  vertical?: boolean;
  allowedTypesMessage?: string;
  maxFiles?: number;
}

export const FileDropZone = ({
  onFileDrop,
  allowedFileTypes = [],
  width,
  height,
  disabled = false,
  vertical = false,
  allowedTypesMessage,
  maxFiles,
}: FileDropZoneProps) => {
  const handleDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => {
    rejectedFiles.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        toast.error(`File ${file.name} rejected: ${error.message}`, {
          duration: 10000,
        });
      });
    });

    acceptedFiles.forEach((file) => {
      onFileDrop?.(file);
    });
  };

  return (
    <Dropzone
      onDropAccepted={(acceptedFiles) => handleDrop(acceptedFiles, [])}
      onDropRejected={(rejectedFiles) => handleDrop([], rejectedFiles)}
      multiple={true}
      maxFiles={maxFiles || 20}
      accept={allowedFileTypes.join(',')}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section>
          <div {...getRootProps()}>
            <input
              {...getInputProps({
                'aria-label': 'File upload',
                accept: allowedFileTypes.join(','),
              })}
              disabled={disabled}
              className="sr-only"
            />
            <div
              className={`flex flex-col items-center justify-center py-6 text-slate-100 transition-all duration-300 ${
                disabled
                  ? 'cursor-not-allowed'
                  : `cursor-pointer ${
                      isDragActive
                        ? 'bg-blue-900'
                        : 'bg-surface border-accent-emphasis hover:border-blue-400 hover:bg-slate-900'
                    }`
              }`}
              style={{
                width: width || '744px',
                height: height || '96px',
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E028DD' stroke-width='2' stroke-dasharray='4%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                borderRadius: '8px',
              }}
            >
              <div className="flex flex-col gap-4 px-4">
                <div
                  className={`flex ${vertical ? 'flex-col' : ''} items-center justify-center ${
                    vertical ? 'gap-4' : 'gap-6'
                  }`}
                >
                  <IconCloudUpload
                    stroke={2}
                    className="text-blue-100"
                    size={56}
                  />
                  <span className="text-sm text-slate-200">
                    Drag & Drop your file(s)
                  </span>
                  <span className="text-sm text-slate-200">-or-</span>
                  <BaseButton
                    type="secondary"
                    size="medium"
                    label="browse"
                    disabled={disabled}
                  >
                    Browse to Upload
                  </BaseButton>
                </div>
                {allowedTypesMessage && (
                  <div className="flex justify-center">
                    <span className="text-sm text-slate-400">
                      {allowedTypesMessage}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};
