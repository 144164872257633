import { gql } from '@apollo/client';

const CREATE_DILIGENCE_NOTE = gql`
  mutation CreateDiligenceNote($input: CreateDiligenceNoteInput!) {
    addDiligenceNote(input: $input) {
      id
      created_time
      diligence_id
      note
      author {
        given_name
        family_name
        role
        is_current_user
      }
    }
  }
`;

const CREATE_COLLATERAL_NOTE = gql`
  mutation CreateCollateralNote($input: AddCollateralNoteInput!) {
    addCollateralNote(input: $input) {
      dealID
      loanID
      fileName
      note
      authorRole
    }
  }
`;

const UPDATE_COLLATERAL_STATUS = gql`
  mutation UpdateCollateralStatus($input: UpdateCollateralStatusInput!) {
    updateCollateralStatus(input: $input)
  }
`;

const UPDATE_DILIGENCE_STATUS = gql`
  mutation UpdateDiligenceStatus($id: ID!, $diligenceStatus: DiligenceStatus!) {
    updateDiligenceStatus(id: $id, diligenceStatus: $diligenceStatus) {
      id
      status
      __typename
    }
  }
`;

const MARK_COLLATERAL_REVIEW_COMPLETE = gql`
  mutation MarkCollateralReviewComplete(
    $input: MarkCollateralReviewCompleteInput!
  ) {
    markCollateralReviewComplete(input: $input) {
      id
      __typename
    }
  }
`;

const MARK_DILIGENCE_ACCEPTED = gql`
  mutation MarkDiligenceAccepted($input: MarkDiligenceAcceptedInput!) {
    markDiligenceAccepted(input: $input) {
      id
      __typename
    }
  }
`;

const REMOVE_COLLATERAL_FILE = gql`
  mutation RemoveCollateralFile($input: RemoveCollateralFileRequest!) {
    removeCollateralFile(input: $input) {
      message
    }
  }
`;


export {
  CREATE_DILIGENCE_NOTE,
  CREATE_COLLATERAL_NOTE,
  UPDATE_COLLATERAL_STATUS,
  UPDATE_DILIGENCE_STATUS,
  MARK_COLLATERAL_REVIEW_COMPLETE,
  MARK_DILIGENCE_ACCEPTED,
  REMOVE_COLLATERAL_FILE,
};
