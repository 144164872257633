import { useEffect, useState } from 'react';

import { BaseButton } from 'common-ui';
import { Dialog, DialogProps } from 'common-ui/Dialog';

import { useMutation } from '@apollo/client';

import { DealMessageAuthorRole } from '__generated__/globalTypes';

import {
  CreateCollateralNote,
  CreateCollateralNoteVariables,
} from 'mutation/__generated__/CreateCollateralNote';
import { CREATE_COLLATERAL_NOTE } from 'mutation/diligenceMutations';

import { useCollateralNotes } from './useCollateralNotes';

interface CollateralNotesDialogProps {
  fileName: string;
  dialog: DialogProps;
  dealID: string;
  loanID: string;
  role: 'BUYER' | 'SELLER';
}

const CollateralNotesDialog: React.FC<CollateralNotesDialogProps> = ({
  fileName,
  dialog,
  dealID,
  loanID,
  role,
}) => {
  const [noteText, setNoteText] = useState<string>('');

  // Use the custom hook for fetching notes
  const { notes, loading, refetch } = useCollateralNotes(
    dealID,
    loanID,
    fileName,
  );

  const [createCollateralNote] = useMutation<
    CreateCollateralNote,
    CreateCollateralNoteVariables
  >(CREATE_COLLATERAL_NOTE);

  const handleAddNote = (text: string) => {
    createCollateralNote({
      variables: {
        input: {
          dealID,
          loanID,
          fileName,
          authorRole: role as DealMessageAuthorRole,
          note: text,
        },
      },
    }).then(() => {
      refetch();
      dialog.setIsOpen(false);
    });
  };

  useEffect(() => {
    if (dialog.isOpen) {
      setNoteText('');
    }
  }, [dialog.isOpen]);

  return (
    <Dialog dialog={dialog}>
      <button
        className="text-grey-500 hover:text-grey-300 absolute right-[10px] top-[10px]"
        onClick={() => dialog.setIsOpen(false)}
      >
        ✕
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold">
        Add a Note for "{fileName}"
      </h2>
      <div className="mt-4 flex max-h-[444px] flex-col gap-2 overflow-y-auto">
        {loading && <div>Loading...</div>}
        {notes.map((note, index) => {
          const isCounterparty = note.authorRole !== role; // Determine message alignment
          return (
            <div
              key={index}
              className={`flex ${isCounterparty ? 'justify-start' : 'justify-end'}`}
            >
              <div
                className={`max-w-[80%] ${isCounterparty ? 'text-left' : 'text-right'}`}
              >
                <div className="text-gray-500">{note.authorRole}</div>
                <div className="font-pt-sans-narrow text-xs text-slate-200">
                  {new Date(note.createdAt * 1000).toDateString()}
                </div>
                <div className="text-sm">{note.note}</div>
              </div>
            </div>
          );
        })}
      </div>
      <textarea
        className="my-4 h-28 w-full rounded-md border border-pink-400 bg-background-surface p-2 text-sm text-foreground-default focus:border-pink-400 focus:outline-none"
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="flex justify-between gap-2">
        <BaseButton
          type="secondary"
          label="cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="add-note"
          size="medium"
          disabled={noteText.length === 0}
          onClick={() => {
            handleAddNote(noteText);
          }}
        >
          Add Note
        </BaseButton>
      </div>
    </Dialog>
  );
};

export default CollateralNotesDialog;
