import { Table } from 'baseui/table-semantic';
import Dinero from 'dinero.js';
import { FormattedNumber } from 'react-intl';

import { SummaryTableCashFlowTotals } from './gql/__generated__/SummaryTableCashFlowTotals';
import { SummaryTablePerformanceSummary } from './gql/__generated__/SummaryTablePerformanceSummary';

type Props = {
  cashFlowTotals: SummaryTableCashFlowTotals | null;
  performanceSummary: SummaryTablePerformanceSummary | null;
};

const SummaryTable = ({
  cashFlowTotals,
  performanceSummary,
}: Props): JSX.Element => {
  const unpaidPrincipalBalance = performanceSummary?.current_balance_cents ?? 0;

  const formatedCashFlowTotal = (value?: number) => {
    return value
      ? Dinero({
          amount: value,
          currency: 'USD',
        }).toFormat('$0,0.00')
      : '-';
  };

  const formattedCashFlowPercentage = (value?: number) => {
    return value ? (
      <FormattedNumber
        style={'percent'}
        value={value / unpaidPrincipalBalance}
        minimumFractionDigits={3}
      />
    ) : (
      '-'
    );
  };

  const COLUMNS = ['', '', ''];
  const DATA = [
    [
      'Principal',
      formatedCashFlowTotal(cashFlowTotals?.principal_cents),
      formattedCashFlowPercentage(cashFlowTotals?.principal_cents),
    ],
    [
      'Interest',
      formatedCashFlowTotal(cashFlowTotals?.interest_cents),
      formattedCashFlowPercentage(cashFlowTotals?.interest_cents),
    ],
    [
      'Prepayments',
      formatedCashFlowTotal(cashFlowTotals?.prepayments_cents),
      formattedCashFlowPercentage(cashFlowTotals?.prepayments_cents),
    ],
    [
      'Losses',
      formatedCashFlowTotal(cashFlowTotals?.defaults_minus_losses_cents),
      formattedCashFlowPercentage(cashFlowTotals?.defaults_minus_losses_cents),
    ],
    [
      'Subtotal',
      formatedCashFlowTotal(cashFlowTotals?.subtotal_cents),
      formattedCashFlowPercentage(cashFlowTotals?.subtotal_cents),
    ],
    [
      'Servicing',
      formatedCashFlowTotal(cashFlowTotals?.servicing_cents),
      formattedCashFlowPercentage(cashFlowTotals?.servicing_cents),
    ],
    [
      'Total',
      formatedCashFlowTotal(cashFlowTotals?.total_cents),
      formattedCashFlowPercentage(cashFlowTotals?.total_cents),
    ],
  ];

  return (
    <Table
      columns={COLUMNS}
      data={DATA}
      overrides={{
        TableHead: {
          style: {
            height: 0,
            display: 'none',
          },
        },
        TableBodyRow: {
          style: () => ({}),
        },
        TableBodyCell: {
          style: ({ $rowIndex, $colIndex }) => ({
            paddingTop: '10px',
            paddingBottom: '10px',
            color: $colIndex === 0 ? '#868e9f' : '#f4f4fd',
            fontSize: $colIndex === 0 ? '12px' : '14px',
            fontFamily: $colIndex === 0 ? 'Heebo' : 'PT Mono',
            minWidth: $colIndex === 0 ? '150px' : '80px',
            textAlign: $colIndex === 0 ? 'left' : 'right',
            borderBottom:
              $rowIndex === 3 || $rowIndex === 5
                ? '1px solid #868e9f'
                : '1px solid #32363e',
          }),
        },
      }}
    />
  );
};

export default SummaryTable;
